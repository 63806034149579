<template>
    <div class="WeeklyPage-nopx">
        <div class="center">
            <ul>
                <router-link to="/accountCenter">
                    <li><i class="iconfont icon-icon_leftbar_zh_black"></i>账户信息</li>
                </router-link>
                <router-link to="/systemInformation">
                    <li><i class="iconfont icon-icon_leftbar_xt_black"></i>系统消息</li>
                </router-link>
                <li class="active"><i class="iconfont icon-icon_leftbar_zb_black"></i>周报</li>
            </ul>
            <div class="content">
                <div class="list">
                    <div class="item mb16" v-for="item in dataList" :key="item.id">
                        <div class="item-top">
                            <div class="title">{{ item.productName }}</div>
                            <div class="time"><span>鑫泰云周报</span>{{ item.createTime }}</div>
                        </div>
                        <div class="search cs" @click="search(item)">查看</div>
                    </div>
                </div>
                <el-pagination layout="prev, pager, next" :total="total" @current-change="currentChange" />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import apiServer from "@/api/request";

export default defineComponent({
    name: "WeeklyPage",
    setup() {

        const pageIndex = ref(1);
        const pageSize = ref(10);
        const total = ref(0);
        const dataList = ref([] as any[]);

        GetWeeklyList();
        function GetWeeklyList() {
            const params = {
                pageIndex: pageIndex.value,
                pageSize: pageSize.value
            }
            apiServer.GetWeeklyList(params).then((res: any) => {
                if (res.status == 1) {
                    total.value = res.data.count;
                    dataList.value = res.data.list;
                }
            })
        }

        function currentChange(value: number) {
            pageIndex.value = value;
            GetWeeklyList();
        }
        
        function search(item:any){
            window.open(`../weeklyMobile.html?orderId=${item.orderId}&weeklyReportId=${item.weeklyReportId}`)
        }
        return {
            total,
            currentChange,
            dataList,
            search
        };
    },
});
</script>
  
<style lang="less" scoped>
.WeeklyPage-nopx {
    .center {
        width: 830px;
        margin: 0 auto;
        margin-top: 24px;
        display: flex;
        justify-content: center;

        ul {
            width: 90px;
            margin-right: 40px;

            li {
                margin-bottom: 28px;
                font-weight: 500;
                font-size: 14px;
                position: relative;
                padding-left: 10px;
                cursor: pointer;
                text-align: left;

                i {
                    margin-right: 5px;
                }

            }

            .active::after {
                content: '';
                display: block;
                width: 4px;
                height: 18px;
                position: absolute;
                left: 0;
                top: 0;
                background: #2074FF;
            }
        }

    }

    .content {
        width: 700px;

        .list {
            .item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: white;
                padding: 14px 16px;
                border-radius: 8px;

                .title {
                    font-weight: 500;
                    text-align: left;
                }

                .time {
                    font-size: 14px;
                    color: #515A6E;
                    margin-top: 10px;

                    span {
                        margin-right: 24px;
                    }
                }

                .search {
                    color: #2074FF;
                    font-size: 14px;
                }
            }
        }
    }

    .el-pagination {
        background: white;
        justify-content: right;
        border-radius: 8px;
        overflow: hidden;
    }
}
</style>
<style lang="less">
.WeeklyPage-nopx {}
</style>