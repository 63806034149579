import config from '@/api/config'
import {SM4} from 'gm-crypto'


const keyStr = config.key
const ivStr = config.iv

function stringToHex(str:string){
  let val="";
  for(let i = 0; i < str.length; i++){
      if(val == "")
          val = str.charCodeAt(i).toString(16);
      else
          val += + str.charCodeAt(i).toString(16);
  }
  return val;

}

const key = stringToHex(keyStr)
const iv = stringToHex(ivStr)


const Security = {
  encode(msg:any) {
    const enRes = SM4.encrypt(msg, key, {
      iv: iv,
      mode: SM4.constants.CBC,
      inputEncoding: 'utf8',
      outputEncoding: 'hex'
     })
     console.log("enResenResenRes",enRes)
     return enRes
  },
  decode(msg:string){
    const deRes = SM4.decrypt(msg, key, {
      iv: iv,
      mode: SM4.constants.CBC,
      inputEncoding: 'hex',
      outputEncoding: 'utf8'
    })
    console.log("deResdeResdeRes",deRes)

  return deRes
  }
}
console.log("-------")
// console.log(Security.decode('0134 d9deb01369caa82388fbd7ab663f24e39da63c7d0eea2b9c276650476eb386e5b1a306a41e7552b33c822013d780aa33d9bdce84706da3258fce01047d9d'))
console.log("-------")

export default Security;
