<template>
    <div class="EmptyPage">
      <div class="empty">
        <img
          src="https://static.nagaiot.cn/web/icon_no.png"
          alt=""
        />
        <p>您尚未被加入任何项目</p>
        <p>请联系管理员将您的账号添加到项目</p>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent,inject} from 'vue';
  
  export default defineComponent({
    name: 'EmptyPage',
    setup(){
      const emitt: any = inject("emitt");
      emitt.$pub("refresh");

      return {
        
      }
    }
  });
  </script>
  
  <style scoped lang="less">
 .EmptyPage{
    padding-top: 150px;
 }
  </style>