import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DataAndManagement from '../views/DataAndManagement.vue'
import MonitoringPanelList from '../views/MonitoringPanelList.vue'
import MonitoringPanel from '../views/MonitoringPanel.vue'
import LoginPage from '../views/LoginPage.vue'
import IndexPage from '../views/IndexPage.vue'
import EmptyPage from '../views/EmptyPage.vue'
import DeviceDetails from '../views/subpage/DeviceDetails.vue'
import ControlDetails from '../views/subpage/ControlDetails.vue'
import AccountCenter from '../views/AccountCenter.vue'
import SystemInformation from '../views/SystemInformation.vue'
import BasicParameters from '../views/BasicParameters.vue'
import WeeklyPage from '../views/WeeklyPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: IndexPage,
    children: [
      {
        path: '/',
        name: 'dataAndManagement',
        component: DataAndManagement,
        children: [
          {
            path: '/',
            name: 'DeviceDetails',
            component: DeviceDetails,
          },
          {
            path: '/controlDetails',
            name: 'ControlDetails',
            component: ControlDetails,
          },
          {
            path: '/basicParameters',
            name: 'BasicParameters',
            component: BasicParameters,
          }
        ]
      },
      {
        path: '/accountCenter',
        name: 'AccountCenter',
        component: AccountCenter,
      },
      {
        path: '/systemInformation',
        name: 'SystemInformation',
        component: SystemInformation,
      },
      {
        path: '/weeklyPage',
        name: 'WeeklyPage',
        component: WeeklyPage,
      },
      {
        path: '/monitoringPanelList',
        name: 'monitoringPanelList',
        component: MonitoringPanelList
      },
      {
        path: '/homeView',
        name: 'homeView',
        component: HomeView
      },
      {
        path: '/emptyPage',
        name: 'emptyPage',
        component: EmptyPage
      },
    ]
  },
  {
    path: '/loginPage',
    name: 'loginPage',
    component: LoginPage,
    meta: {
      headHideFlag: true
    }
  },
  {
    path: '/monitoringPanel',
    name: 'monitoringPanel',
    component: MonitoringPanel
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
