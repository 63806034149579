import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6d671814"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "RealTimeData" }
const _hoisted_2 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.dialogVisible,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dialogVisible) = $event)),
      title: "实时数据展示管理",
      width: "300",
      "show-close": false,
      "before-close": _ctx.handleClose
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, [
          _createVNode(_component_el_button, {
            class: "btn",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialogVisible = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode("取消")
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            class: "btn",
            type: "primary",
            onClick: _ctx.save
          }, {
            default: _withCtx(() => [
              _createTextVNode(" 保存 ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_el_checkbox_group, {
            modelValue: _ctx.checkList,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkList) = $event))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deviceList, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "tl",
                  key: item.deviceControlId
                }, [
                  _createVNode(_component_el_checkbox, {
                    label: item.deviceControlId
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.deviceControlName), 1)
                    ]),
                    _: 2
                  }, 1032, ["label"])
                ]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "before-close"])
  ]))
}