import { Storage } from "@/customizeObj/storage";

const storage = new Storage();

import {
    USERTOKEN,
  } from '../mutation-type';

  const state = {
    userToken: storage.localGet('userToken') || '',
    ...(storage.localGet('iot.state') ? storage.localGet('iot.state').user : {}),
  };
  
  const mutations = {
    [USERTOKEN]: (s:any, userToken:string) => {
        const copy = s;
        copy.userToken = userToken;
        localStorage.userToken = userToken;
      },
  }
  export default {
    state,
    mutations,
  };
  