<template>
  <div class="ControlDetails-nopx">
    <el-tabs v-model="activeName" class="demo-tabs">
      <el-tab-pane name="control">
        <template #label>
          <span class="custom-tabs-label">
            <i class="iconfont icon-icon_bar_kz_blue"></i>
            <span>控制&数据</span>
          </span>
        </template>
        <DeviceComponent :deviceName="deviceName" :deviceId="deviceId" :deviceTypeId="deviceTypeId" />
      </el-tab-pane>
      <el-tab-pane name="warn">
        <template #label>
          <span class="custom-tabs-label">
            <i class="iconfont icon-icon_bar_bj_black"></i>
            <span>报警</span>
          </span>
        </template>
        <WarnComponent :deviceName="deviceName" :deviceId="deviceId" />
      </el-tab-pane>
      <el-tab-pane name="consumables">
        <template #label>
          <span class="custom-tabs-label">
            <i class="iconfont icon-icon_bar_hc_black"></i>
            <span>耗材</span>
          </span>
        </template>
        <ConsumablesComponent :deviceName="deviceName" :deviceId="deviceId" />
      </el-tab-pane>
      <el-tab-pane name="log">
        <template #label>
          <span class="custom-tabs-label">
            <i class="iconfont icon-icon_bar_rz_black"></i>
            <span>日志</span>
          </span>
        </template>
        <logComponent :deviceName="deviceName" :deviceId="deviceId" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import DeviceComponent from '../../components/DeviceComponent.vue'
import WarnComponent from '../../components/WarnComponent.vue'
import ConsumablesComponent from '../../components/ConsumablesComponent.vue'
import logComponent from '../../components/logComponent.vue'
export default defineComponent({
  name: "ControlDetails",
  components: {
    DeviceComponent,
    WarnComponent,
    ConsumablesComponent,
    logComponent,
  },
  setup() {
    const activeName = ref("control");
    const deviceName = ref('');
    const deviceTypeId = ref('');
    const deviceId = ref('');
    if (localStorage.device) {
      deviceName.value = JSON.parse(localStorage.device).deviceName
      deviceTypeId.value = JSON.parse(localStorage.device).deviceTypeId
      deviceId.value = JSON.parse(localStorage.device).deviceId
    }

    return {
      activeName,
      deviceName,
      deviceTypeId,
      deviceId
    };
  },
});
</script>

<style lang="less" scoped>
.ControlDetails-nopx {
  width: calc(100vw - 240px);
  float: right;
  min-width: 1200px;
}
</style>
<style lang="less">
.ControlDetails-nopx {
  .el-tabs {
    .el-tabs__header {
      background: white;
    }

    .el-tabs__nav-wrap::after {
      display: none;
    }

    .el-tabs__nav {
      margin: 0 auto;
    }

    .el-tabs__nav-scroll {
      display: flex;
      justify-content: center;
      height: 48px;
    }

    .el-tabs__item {
      margin: 0 39px;
      padding: 0;
      height: 48px;

      .iconfont {
        margin-right: 4px;
      }
    }
  }
}</style>
