<template>
  <div class="AdjustModal">
    <el-dialog v-model="dialogVisible" :title="item.deviceControlName + '-调整'" width="300" :show-close="false"
      :before-close="handleClose">
      <div class="box">
        <div class="value">
          <el-input type="number" v-model="item.monitorValue" />
        </div>
        <div class="unit">{{ item.dataUnit }}</div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="controllableChange">
            保存
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, inject, reactive, onBeforeUnmount } from "vue";
import apiServer from "@/api/request";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "AdjustModal",
  props: {
    organId: {
      type: String,
    },
    orderId: {
      type: String,
    },
  },
  setup(props) {
    const emitt: any = inject("emitt");
    const dialogVisible = ref(false);
    const item = reactive({ monitorValue: "", deviceControlId: "", deviceControlName: '', dataUnit: '', checkMethod: '' });
    function handleClose() {
      console.log("handleClose");
    }

    //注册-打开弹窗
    emitt.$sub("openAdjustModal", (val: any) => {
      let v = val[0];
      Object.assign(item, v);
      dialogVisible.value = true;
    });


    function controllableChange() {
      item.checkMethod = '3'
      if (item.checkMethod.toString() == "2" || item.checkMethod.toString() == "3") {
        const func = {
          method: WriteData,
          item: item,
        }
        emitt.$pub("openSafetyVerificationModal", func);
      }else{
        WriteData(item,'')
      }

    }

    function WriteData(item: any,code:any) {
      const params = {
        deviceControlId: item.deviceControlId,
        monitorValue: item.monitorValue,
        code:code
      };
      const data = {
        orderId: props.orderId,
      };
      console.log(params);
      apiServer.WriteData(params, data).then((res: any) => {
        console.log(res);
        if (res.status == 1) {
          ElMessage.success('调整成功！');
          dialogVisible.value = false;
        } else {
          ElMessage.error(res.message);
        }
      });
    }
    onBeforeUnmount(() => {
      emitt.$unsub("openAdjustModal");
    });
    return {
      dialogVisible,
      item,
      handleClose,
      controllableChange,
    };
  },
});
</script>

<style scoped lang="less">
.AdjustModal {
  .box {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .value {
    width: 220px;

    input {
      text-align: center;
    }
  }

  .unit {
    margin-left: 10px;
    font-weight: 600;
  }
}
</style>
<style lang="less">
.AdjustModal {
  .el-button {
    width: 120px;
  }

  .el-input__inner {
    text-align: center;
  }
}
</style>
