<template>
    <div class="ConsumablesComponent ConsumablesComponent-nopx">
        <div class="title">{{ deviceName }}</div>
        <div class="status_box">
            <div class="status_item mb16" v-for="item in consumablesList" :key="item.consumableId">
                <div class="status_title">耗材状态 <span class="cs" @click="openDialog(item)">录入更换</span>
                </div>
                <div class="status_content">
                    <div class="content">
                        <div class="value fs18">{{ item.consumableName }}</div>
                        <div class="name fs14">耗材名称</div>
                    </div>
                    <div class="content">
                        <div class="value fs18">{{ item.startUsageTime }}</div>
                        <div class="name fs14">上次更换时间</div>
                    </div>
                    <div class="content">
                        <div class="value fs18">{{ item.lifetime }}m³</div>
                        <div class="name fs14">剩余寿命</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="table">
            <div class="table_head">更换记录</div>
            <div class="tabs">
                <el-tabs v-model="activeName" class="demo-tabs" @tab-change="handleChange">
                    <el-tab-pane v-for="item in consumablesList" :key="item.consumableLinkId" :label="item.consumableName"
                        :name="item.consumableLinkId"></el-tab-pane>
                </el-tabs>
            </div>
            <div class="el_table">
                <el-table v-loading="tableLoding" :data="tableData" style="width: 100%; text-align: left">
                    <el-table-column prop="consumableName" label="名称" width="180" />
                    <el-table-column prop="updateTime" label="更换时间" />
                    <el-table-column prop="lifetime" label="更换后寿命">
                        <template #default="scope">
                            {{ scope.row.lifetime }}m³
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-dialog v-model="dialogVisible" :title="title + '更换'" width="300" :before-close="handleClose"
            :show-close="false">
            <el-input v-if="resultFlag" v-model="consumableStoreCode" size="large" placeholder="请输入耗材编号" />
            <div class="result" v-else>
                <div>录入时间：<span>{{ newConsumable.startUsageTime }}</span></div>
                <div class="mt16">耗材寿命：<span>{{ newConsumable.lifetime }}m³</span></div>
                <div class="mt16 break-word">耗材编号：<span>{{ newConsumable.consumableStoreCode }}</span></div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button class="btn" size="large" @click="dialogVisible = false">取消</el-button>
                    <el-button class="btn" size="large" type="primary" @click="Confirm">
                        确定
                    </el-button>
                </span>
            </template>
        </el-dialog>
        <FooterComponent />

    </div>
</template>
<script lang="ts">
import { defineComponent, ref, reactive, inject } from "vue";
import apiServer from "@/api/request";
import { ElMessage, ElMessageBox } from "element-plus";
import FooterComponent from "@/components/FooterComponent.vue";

export default defineComponent({
    name: "ConsumablesComponent",
    props: {
        deviceName: {},
        deviceId: {},
    },
    components: {
        FooterComponent
    },
    setup(props) {

        const dialogVisible = ref(false);
        const orderId = ref(JSON.parse(localStorage.GetCategory).orderId);
        const deviceId = ref(props.deviceId);
        const tableData = reactive([] as any[]);
        const title = ref('');
        const consumableStoreCode = ref('');//编号
        const resultFlag = ref(true);
        const consuObj = ref({});
        const activeName = ref('');
        const consumablesList = reactive([] as any[]);//耗材列表
        const tableLoding = ref(false);
        const consumableLinkId = ref("");
        const consumableId = ref("");
        const newConsumable = reactive({
            consumableStoreCode: "",
            startUsageTime: "",
            lifetime: ""
        })
        DeviceConsumableGetList();

        function DeviceConsumableGetList() {
            const params = {
                orderId: orderId.value,
                monitorDeviceId: deviceId.value,
            };
            apiServer.DeviceConsumableGetList(params).then((res: any) => {
                if (res.status == 1) {
                    if (res.data.list.length == 0) {
                        return
                    }
                    consumablesList.push(...res.data.list);
                    activeName.value = res.data.list[0].consumableLinkId
                    GetConsumableHistory();
                }
            })
        }

        //查询更换记录
        function GetConsumableHistory() {
            const params = {
                orderId: orderId.value,
                consumableLinkId: activeName.value,
            };
            apiServer.GetConsumableHistory(params).then((res: any) => {
                if (res.status == 1) {
                    tableData.length = 0;
                    tableData.push(...res.data.list)
                } else {
                    ElMessage.error(res.message)
                }
            });
        }

        function handleClose() {
            console.log("handleClose")
        }

        function openDialog(item: any) {
            dialogVisible.value = true;
            title.value = item.consumableName;
            consumableLinkId.value = item.consumableLinkId
            consumableId.value = item.consumableId
        }

        function handleChange() {
            GetConsumableHistory();
        }

        function GetConsumable() {
            const params = {
                monitorDeviceId: deviceId.value,
                consumableStoreCode: consumableStoreCode.value
            }
            apiServer.GetConsumable(params).then((res: any) => {
                if (res.status == 1) {
                    console.log(res)
                    Object.assign(newConsumable, res.data)
                    resultFlag.value = false;
                } else {
                    ElMessage.error(res.message)
                }
            })
        }

        //更换耗材
        function UpdateConsumable() {
            const params = {
                orderId: orderId.value,
                monitorDeviceId: deviceId.value,
                consumableLinkId: consumableLinkId.value,
                consumableId: consumableId.value,
                consumableStoreCode: consumableStoreCode.value
            }
            apiServer.UpdateConsumable(params).then((res: any) => {
                if (res.status == 1) {
                    dialogVisible.value = false;
                    activeName.value = consumableLinkId.value;
                    GetConsumableHistory();
                } else {
                    ElMessage.error(res.message)
                }
            })
        }

        function Confirm() {
            if (resultFlag.value) {
                GetConsumable();
            } else {
                UpdateConsumable();
            }
        }
        return {
            Confirm,
            tableData,
            dialogVisible,
            handleClose,
            title,
            consumableStoreCode,
            openDialog,
            resultFlag,
            consuObj,
            tableLoding,
            activeName,
            handleChange,
            consumablesList,
            newConsumable
        };
    },
});
</script>
  
<style lang="less" scoped>
.ConsumablesComponent-nopx {
    height: calc(100vh - 123px);
    overflow-y: scroll;

    .title {
        font-size: 20px;
        font-weight: 500;
        text-align: left;
        padding: 0 24px;

    }

    .status_box {
        padding: 0 24px;
        margin-top: 20px;

        .status_item {
            background: white;
            border-radius: 8px;

            .status_title {
                padding: 0 16px;
                font-size: 16px;
                height: 52px;
                line-height: 52px;
                border-bottom: 1px solid #E7E7E7;
                text-align: left;
                font-weight: 500;

                span {
                    color: #2074FF;
                    float: right;
                }
            }

            .status_content {
                display: flex;
                justify-content: space-around;
            }

            .content {
                padding: 24px 16px;

                .value {
                    font-weight: 600;
                }

                .name {
                    color: #515A6E;
                    margin-top: 8px;
                }
            }
        }
    }

    .result {
        text-align: left;

        div {
            display: flex;
            justify-content: left;
        }

        span {
            width: 180px;
        }
    }

    .table {
        margin: 18px 24px;
        border-radius: 8px;
        overflow: hidden;

        .table_head {
            text-align: left;
            font-size: 16px;
            font-weight: 500;
            height: 52px;
            line-height: 52px;
            background: white;
            padding: 0 16px;
            border-bottom: 1px solid #E7E7E7;
        }

        .el_table {
            padding: 8px 16px;
            background: white;
        }
    }
}
</style>
<style lang="less">
.ConsumablesComponent-nopx {
    .dialog-footer {
        display: flex;
        justify-content: space-between;
        padding: 0 4px;

        .btn {
            width: 118px;
        }
    }

    .el-tabs__nav {
        margin: 0 !important;
        float: left;
        height: 40px !important;
        line-height: 40px !important;
    }

    .el-tabs__item {
        height: 40px !important;
        margin: 0 30px 0 0 !important;
    }

    .el-tabs__nav-scroll {
        justify-content: left !important;
        height: 40px !important;
        padding-left: 16px;
    }

    .el-tabs__header {
        margin: 0;
    }
}
</style>
  