<template>
    <div class="BasicParameters BasicParameters-nopx">
        <div class="box">
            <div class="left_box">
                <div class="th">
                    <div class="td">名称</div>
                    <div class="td">设定值</div>
                    <div class="td">单位</div>
                </div>
                <div class="tr" v-for="item of dataListLeft" :key="item.componentCode">
                    <div class="td">{{ item.configName }}</div>
                    <div class="td">
                        <el-input v-model="item.configValue" placeholder="Please input" />
                    </div>
                    <div class="td">{{ item.unitDesc }}</div>
                </div>
            </div>
            <div class="right_box">
                <div class="th">
                    <div class="td">名称</div>
                    <div class="td">设定值</div>
                    <div class="td">单位</div>
                </div>
                <div class="tr" v-for="item of dataListRight" :key="item.componentCode">
                    <div class="td">{{ item.configName }}</div>
                    <div class="td">
                        <el-input v-model="item.configValue" placeholder="Please input" />
                    </div>
                    <div class="td">{{ item.unitDesc }}</div>
                </div>
            </div>

        </div>
        <div class="save">
            <el-button type="primary" @click="SetConfigInfo">保存修改</el-button>
        </div>
        <FooterComponent />

    </div>
</template>
<script lang="ts">
import { defineComponent, ref, inject, reactive } from "vue";
import apiServer from "@/api/request";
import { ElMessage } from "element-plus";
import FooterComponent from "@/components/FooterComponent.vue";

export default defineComponent({
    name: "BasicParameters",
    components: {
        FooterComponent
    },
    setup() {

        const orderId = JSON.parse(localStorage.GetCategory).orderId;
        const dataListLeft = reactive([] as any[]);
        const dataListRight = reactive([] as any[]);
        const input = ref('');

        GetConfigList();
        function GetConfigList() {
            const params = {
                orderId
            }
            apiServer.GetConfigList(params).then((res: any) => {
                if (res.status == 1) {
                    let list = res.data.list;
                    for (let i in list) {
                        if (Number(i) % 2 == 0) {
                            dataListLeft.push(list[i])
                        } else {
                            dataListRight.push(list[i])
                        }
                    }
                    console.log(dataListLeft)
                    console.log(dataListRight)
                }
            })
        }


        function SetConfigInfo() {
            const arr = [...dataListLeft, ...dataListRight]
            console.log("arr", arr)
            const params = {
                body: arr
            }
            const data = {
                orderId
            }
            apiServer.SetConfigInfo(params, data).then((res: any) => {
                if (res.status == 1) {
                    ElMessage.success("保存成功");
                } else {
                    ElMessage.error(res.message);
                }
            })
        }
        return {
            input,
            dataListLeft,
            dataListRight,
            SetConfigInfo
        };
    },
});
</script>
  
<style lang="less" scoped>
.BasicParameters-nopx {
    width: calc(100vw - 240px);
    float: right;
    min-width: 1200px;
    height: calc(100vh - 60px);
    overflow-y: scroll;
    padding-bottom: 40px;

    .box {
        display: flex;
        justify-content: space-between;
        padding: 16px 24px;
    }

    .save {
        text-align: left;
        padding-left: 24px;
    }

    .left_box,
    .right_box {
        width: 49%;
        background: white;

        .th {
            background: #F5F7F9;
            display: flex;
            justify-content: left;
            height: 40px;
            line-height: 40px;
            color: #515A6E;
            font-size: 14px;

            .td {
                &:nth-child(1) {
                    padding-left: 16px;
                    text-align: left;
                    font-weight: 500;
                }

                &:nth-child(2) {
                    text-align: center;
                    font-weight: 500;
                }

                &:nth-child(3) {
                    text-align: center;
                    font-weight: 500;
                }
            }
        }

        .tr {
            display: flex;
            justify-content: left;
            height: 48px;
            line-height: 48px;
            font-size: 14px;
            border-bottom: 1px solid #E7E7E7;

            .td {
                &:nth-child(1) {
                    padding-left: 16px;
                    text-align: left;
                }

                &:nth-child(2) {
                    text-align: center;
                    padding: 0 24px;
                }

                &:nth-child(3) {
                    text-align: center;
                }
            }
        }
    }
}

.BasicParameters {
    .th,.tr {

        .td {
            &:nth-child(1) {
                width: 400px;
            }

            &:nth-child(2) {
                width: 200px;
            }

            &:nth-child(3) {
                width: calc(100% - 600px);
            }
        }
    }
}
</style>
<style lang="less">
.BasicParameters-nopx {
    .el-input__inner {
        text-align: center;
        color: #2074FF;
    }
}
</style>