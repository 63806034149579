<template>
    <div class="SafetyVerificationModal SafetyVerificationModal-nopx">
        <el-dialog v-model="dialogVisible" title="安全验证" width="300" :show-close="false" :before-close="handleClose"
            @closed="closed" :close-on-click-modal="false">
            <el-tabs v-model="activeName" class="demo-tabs" @tab-change="handleClick">
                <el-tab-pane label="安全码" name="1" v-if="func.item.checkMethod == 2">
                    <div class="box">
                        <el-input size="large" v-model="code" placeholder="请输入安全码" />
                    </div>
                </el-tab-pane>
                <el-tab-pane label="短信验证" name="2" v-if="func.item.checkMethod == 3">
                    <div class="box code_box">
                        <el-input size="large" v-model="code" placeholder="请输入短信验证码">
                            <template #append>
                                <span @click="GetByMobile">{{ sendMessage }}</span>
                            </template>
                        </el-input>
                    </div>
                </el-tab-pane>
            </el-tabs>
            <template #footer>
                <span class="dialog-footer">
                    <el-button class="btn" size="large" @click="dialogVisible = false">取消</el-button>
                    <el-button class="btn" size="large" :disabled="disabled" type="primary" @click="confirm">
                        确定
                    </el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed, inject, onBeforeUnmount, reactive } from "vue";
import apiService from "@/api/request";
import { ElMessage } from "element-plus";

export default defineComponent({
    name: "SafetyVerificationModal",
    setup() {
        const dialogVisible = ref(false);
        const activeName = ref("1");
        const code = ref('');
        const emitt: any = inject("emitt");
        const func = reactive({} as any)
        const sendMessage = ref('发送');

        //注册-打开弹窗
        emitt.$sub("openSafetyVerificationModal", (val: any) => {
            console.log("openSafetyVerificationModal")
            Object.assign(func, val[0])
            if (func.item.checkMethod == 2) {
                activeName.value = '1';
            } else {
                activeName.value = '2';
            }
            dialogVisible.value = true;
        });

        const disabled = computed(() => {
                return code.value == ''
        })

        function handleClick(e: any) {
                code.value = '';
        }

        function handleClose() {
            dialogVisible.value = false;
        }

        function closed() {
            code.value = '';
        }

        //发送验证码
        let timer: number | null = null;
        const time = ref(60);

        function GetByMobile() {
            if (timer) return;
            try {
                var captcha = new window.TencentCaptcha("197132060", sendCodeFunc, {
                    userLanguage: "cn",
                    needFeedBack: false,
                });
                captcha.show();
            } catch (error) {
                loadErrorCallback(error);
            }

            function sendCodeFunc(result: any) {
                console.log("result", result)
                if (result.ret != 0) return;
                sendMessage.value = "正在发送";
                const params = {
                    smsType: 30,
                    phone: window.btoa(JSON.parse(localStorage.userInfo).phone),
                    mobileAreaCode: JSON.parse(localStorage.userInfo).mobileareacode,
                    ticket: result.ticket,
                    randstr: result.randstr
                };
                apiService.GetByMobile(params).then((res: any) => {
                    if (res.status == 1) {
                        sendMessage.value = `${time.value}S`;
                        timer = setInterval(() => {
                            sendMessage.value = `${time.value}S`;
                            time.value -= 1;
                            if (time.value <= 0) {
                                clearInterval(timer as number);
                                sendMessage.value = "重新发送";
                                time.value = 60;
                                timer = null;
                            }
                        }, 1000);
                    } else {
                        ElMessage.error(res.message);
                        sendMessage.value = "获取验证码";
                    }
                });
            }

            function loadErrorCallback(res: any) {
                var appid = "190327966";
                // 生成容灾票据或自行做其它处理
                var ticket =
                    "terror_1001_" +
                    appid +
                    "_" +
                    Math.floor(new Date().getTime() / 1000);
                callback({
                    ret: 0,
                    randstr: "@" + Math.random().toString(36).substr(2),
                    ticket: ticket,
                    errorCode: 1001,
                    errorMessage: "jsload_error",
                });
            }

            function callback(res: any) {
                console.log("callback:", res);
                if (res.ret === 0) {
                    console.log(res);
                }
            }
        }

        function confirm() {
            func!.method(func!.item,code.value)
            dialogVisible.value = false;
        }
        onBeforeUnmount(() => {
            emitt.$unsub("openSafetyVerificationModal");
        });
        return {
            dialogVisible,
            handleClose,
            activeName,
            disabled,
            code,
            handleClick,
            GetByMobile,
            confirm,
            closed,
            func,
            sendMessage
        }
    },
});
</script>
  
<style lang="less" scoped>
.SafetyVerificationModal-nopx {
    .box {
        padding: 0 24px;
    }

    .btn {
        width: 118px;
    }
}
</style>
<style lang="less" >
.SafetyVerificationModal-nopx {
    .el-tabs__header {
        margin-bottom: 23px;
    }

    .el-dialog__header {
        border-bottom: 0 !important;
    }

    .el-dialog__body {
        padding: 0 !important;
    }

    .el-dialog__footer {
        padding: 32px 24px 24px;
        width: 100%;
    }

    .dialog-footer {
        display: flex;
        justify-content: space-between;
    }

    .el-tabs__item {
        margin: 0 20px !important;
    }

    .el-tabs__nav-scroll {
        border-bottom: 1px solid #E7E7E7;
    }

    .code_box {
        .el-input__wrapper {
            box-shadow: 0 1px 0 0 var(--el-input-border-color) inset, 0 -1px 0 0 var(--el-input-border-color) inset, 1px 0 0 0 var(--el-input-border-color) inset;
        }
    }

    .el-input-group__append {
        background: white;
        padding: 0 12px;
        font-size: 14px;
        color: #2074FF;
        cursor: pointer;
    }
}
</style>