import {getBrowser,urlEncode} from '@/utils/common'


export class Params extends Object{
    packType:string;
    version:string;
    proxyid:string;
    appkey:string;
    ip:string;
    fundTerminal:number;
    deviceInfo:string;
    userToken:string;
    appid:string;
    constructor(userToken=''){
        super();
        this.packType = '1';
        this.version = '1.0.0'
        this.proxyid = sessionStorage.proxyId  || sessionStorage.proxyid  || '1',
        this.appkey = '5b0799fed5b0461681d371c015b23282',
        this.deviceInfo = getBrowser(),
        this.ip = '192.168.0.1'
        this.fundTerminal = 1
        this.userToken = userToken
        this.appid = "1"
    }

    //获取参数拼接
    getUrlEncode(params:object={}){
        console.log("this",urlEncode(Object.assign(this,params)))
        return urlEncode(Object.assign(this,params))
    }

    //获取参数合并
    getAssignParams(params:object={}){
        return Object.assign(this,params)
    }
   
}