import { createStore } from 'vuex';
import getters from './getters/index';
import user from './modules/user';
import project from './modules/project';




export default createStore({
    getters,
    modules: {
        user,
        project
      },
})