import server from './http'

/**
 * 控制相关站点
 */

//查询设备监控操作日志
const GetDeviceMonitorLog = (params={}) => server.controlhttp.get('api/Device/GetDeviceMonitorLog', params);
//更换耗材
const UpdateConsumable = (params={}) => server.controlhttp.get('api/DeviceConsumable/UpdateConsumable', params);
//查询库存信息
const GetConsumable = (params={}) => server.controlhttp.get('api/DeviceConsumable/GetConsumable', params);
//查询耗材更换记录
const GetConsumableHistory = (params={}) => server.controlhttp.get('api/DeviceConsumable/GetConsumableHistory', params);
//获取耗材列表
const DeviceConsumableGetList = (params={}) => server.controlhttp.get('api/DeviceConsumable/GetList', params);
//添加项目(扫码添加)
const AddOrder = (params={},data={}) => server.controlhttp.post('api/Product/AddOrder', params,data);
//校验项目(扫码添加项目)
const CheckOrder = (params={}) => server.controlhttp.get('api/Product/CheckOrder', params);
//查询设备列表(历史记录下拉列表，触发下拉)
const GetDeviceControlList = (params={}) => server.controlhttp.get('api/DeviceControl/GetDeviceControlList', params);
//查看监控设备实时数据
const GetRealTimeControlData = (params={}) => server.controlhttp.get('api/Device/GetRealTimeControlData', params);
//查看监控设备列表
const GetMonitorDeviceList = (params={}) => server.controlhttp.get('api/Device/GetMonitorDeviceList', params);
//获取产品设备大分类
const GetCategory = (params={}) => server.controlhttp.get('api/Product/GetCategory', params);
//查询设备监控历史记录-所有的
const GetMonitorHistory = (params={}) => server.controlhttp.get('api/DeviceControl/GetMonitorHistory', params);
//设置控制显示状态
const SetControlShowStatus = (params={},data={}) => server.controlhttp.post('api/Device/SetControlShowStatus',params,data,"json");
//查看控制列表(含详情)-可控的
const GetControlList = (params={}) => server.controlhttp.get('api/DeviceControl/GetControlList',params);
console.log("process.env.VUE_APP_ENV == 'dev'",process.env.VUE_APP_ENV)
//设备控制
const WriteData = (params={},data={}) => server.controlhttp.post('api/v2/DeviceControl/WriteData',params,data,'',process.env.VUE_APP_ENV !== 'local');
//设置定时触发
const SetTimerTrigger = (params={},data={}) => server.controlhttp.post('api/DeviceControl/SetTimerTrigger',params,data,'json');
//设置条件触发
const SetConditionTrigger = (params={},data={}) => server.controlhttp.post('api/Device/SetConditionTrigger',params,data,'json');
//查询工控设备列表
const GetList = (params={}) => server.controlhttp.get('api/HMI/GetOperatingStatusList',params);
//查询操作记录
const GetOperateRecord = (params={}) => server.controlhttp.get('api/HMI/GetOperateRecord',params);
//查询变化的工控设备列表
const GetChangeList = (params={}) => server.controlhttp.get('api/IndustrialProcess/GetChangeList',params);
//查询工控系统监控状态
const GetMonitorStatus = (params={}) => server.controlhttp.get('api/IndustrialProcess/GetMonitorStatus',params);
//查询工控系统分类列表
const GetIndustrialProcessType = (params={}) => server.controlhttp.get('api/HMI/GetHmiTypeList',params);
//查询警告未读条数
const GetUnReadNum = (params={}) => server.controlhttp.get('api/Device/GetUnReadNum',params);
//消音按钮
const MufflingAlarm = (params={}) => server.controlhttp.get('api/HMI/MufflingAlarm',params);
//设备报警记录
const GetAlarmRecord = (params={}) => server.controlhttp.get('api/HMI/GetAlarmRecord',params);
//查询水质列表
const GetWaterQualityPage = (params={}) => server.controlhttp.get('api/HMI/GetWaterQualityList',params);
//查询耗材列表
const GetConsumablePage = (params={}) => server.controlhttp.get('api/HMI/GetConsumableList',params);
//耗材更换按钮
const SuppliesReset = (params={}) => server.controlhttp.get('api/IndustrialProcess/SuppliesReset',params);
//查询大屏监控设备列表
const GetDeviceList = (params={}) => server.controlhttp.get('api/LargeScreenPanel/GetDeviceList',params);
//查询大屏列表
const LargeScreenPanelGetList = (params={}) => server.controlhttp.get('api/LargeScreenPanel/GetList',params);
//查询大屏详情
const GetDetail = (params={}) => server.controlhttp.get('api/LargeScreenPanel/GetDetail',params);
//查询报警记录
const GetMonitoringAlarmRecord = (params={}) => server.controlhttp.get('api/Device/GetMonitoringAlarmRecord',params);
//设置消息已读
const SetAsRead = (params={},data={}) => server.controlhttp.post('api/Device/SetAsRead',params,data);
//查询监控报警设置
const GetMonitoringAlarmSetting = (params={}) => server.controlhttp.get('api/Device/GetMonitoringAlarmSetting',params);
//设置监控数据
const SetMonitoringAlarmData = (params={},data={}) => server.controlhttp.post('api/Device/SetMonitoringAlarmData',params,data,'json');
//查询统计展示数据
const GetDisplayBasicStatisticData = (params={}) => server.controlhttp.get('api/Device/GetDisplayBasicStatisticData',params);
//查询基础统计数据
const GetBasicStatisticDataList = (params={}) => server.controlhttp.get('/api/Device/GetBasicStatisticDataList',params);
//设置统计展示数据
const SetStatisticData = (params={},data={}) => server.controlhttp.post('/api/Device/SetStatisticData',params,data,'json');
//查看系统消息
const GetSystemMessage = (params={}) => server.controlhttp.get('api/Device/GetSystemMessage',params);
//获取周报列表
const GetWeeklyList = (params={}) => server.controlhttp.get('api/Device/GetWeeklyList',params);
//查看周报详情(统计图)
const GetWeeklyDetailStatistics = (params={}) => server.controlhttp.get('/api/v2/Order/GetWeeklyDetailStatistics',params);
//查询周报详情（表格统计）
const GetWeeklyDetailTable = (params={}) => server.controlhttp.get('api/v2/Order/GetWeeklyDetailTable',params);
//查看状态控制列表
const GetStatusControlList = (params={}) => server.controlhttp.get('api/HMI/GetStatusControlList',params);
//查询工控系统运行状态
const GetRunStatus = (params={}) => server.controlhttp.get('api/HMI/GetRunStatus',params);
//查询水质列表
const GetWaterQualityList = (params={}) => server.controlhttp.get('api/HMI/GetWaterQualityList',params);
//查询大屏运行状态
const GetOverallOperationalStatus = (params={}) => server.controlhttp.get('api/LargeScreenPanel/GetOverallOperationalStatus',params);
//查询大屏统计数据
const GetChartStatistic = (params={}) => server.controlhttp.get('api/LargeScreenPanel/GetChartStatistic',params);
//查询大屏统计数据
const GetCameraAddress = (params={}) => server.controlhttp.get('api/LargeScreenPanel/GetCameraAddress',params);
//查询设备参数配置列表
const GetConfigList = (params={}) => server.controlhttp.get('api/Product/GetConfigList',params);
//设置运行模式
const SetRunModel = (params={},data={}) => server.controlhttp.post('api/Device/SetRunModel',params,data);
//查询运行模式
const GetRunModel = (params={}) => server.controlhttp.get('api/Device/GetRunModel',params);
//设置设备参数配置
const SetConfigInfo = (params={},data={}) => server.controlhttp.post('api/Product/SetConfigInfo',params,data,'json');
//获取产品设备大分类HMI
const GetCategoryHMI = (params={}) => server.controlhttp.get('api/HMI/GetCategory', params);
//查询运行模式HMI
const GetRunModelHMI = (params={}) => server.controlhttp.get('api/HMI/GetRunModel',params);
//设置运行模式HMI
const SetRunModelHMI = (params={},data={}) => server.controlhttp.post('api/HMI/SetRunModel',params,data);
//查询变化的工控设备列表HMI
const GetChangeListHMI = (params={}) => server.controlhttp.get('api/HMI/GetChangeList',params);
//查询水质统计
const GetWaterQualityStatistics = (params={}) => server.controlhttp.get('api/Product/GetWaterQualityStatistics',params);
//HMI获取网络信息
const GetInternetInfo = (params={}) => server.controlhttp.get('api/HMI/GetInternetInfo',params);
//HMI获取网络信息
const WifiConnect = (params={},data={}) => server.controlhttp.post('api/v2/GatewayDevice/WifiConnect',params,data);
/**
 * 用户站点
 */

//一键登入/注册-验证码
const OneKeyLoginVcode = (params={}) => server.http.post('api/User/OneKeyLoginVcode', params);
//获取验证码-手机
const GetByMobile = (params={}) => server.http.post('api/VerifyCode/GetByMobile', params);
//账号密码登录
const Login = (params={}) => server.http.post('api/User/Login', params);
//设置用户头像
const SetHeadPic = (params={}) => server.http.post('api/User/SetHeadPic', params);
//设置密码
const ResetPassword = (params={}) => server.http.post('api/User/ResetPassword', params);
//查询用户信息
const GetUserInfo = (params={}) => server.http.get('api/User/GetUserInfo', params);
//修改用户昵称
const SetNickName = (params={}) => server.http.get('api/User/SetNickName', params);

console.log(server)
const apiServer = {
    WifiConnect,
    GetInternetInfo,
    GetWaterQualityStatistics,
    GetChangeListHMI,
    SetRunModelHMI,
    GetRunModelHMI,
    GetCategoryHMI,
    GetRunModel,
    GetCameraAddress,
    GetChartStatistic,
    GetOverallOperationalStatus,
    GetWaterQualityList,
    GetRunStatus,
    SetRunModel,
    GetStatusControlList,
    GetWeeklyDetailTable,
    GetWeeklyDetailStatistics,
    ResetPassword,
    SetNickName,
    GetDeviceMonitorLog,
    GetRealTimeControlData,
    GetCategory,
    OneKeyLoginVcode,
    GetByMobile,
    GetMonitorHistory,
    GetMonitorDeviceList,
    SetControlShowStatus,
    GetControlList,
    Login,
    WriteData,
    SetTimerTrigger,
    SetConditionTrigger,
    GetList,
    GetChangeList,
    GetMonitorStatus,
    MufflingAlarm,
    GetAlarmRecord,
    GetDeviceList,
    LargeScreenPanelGetList,
    GetDetail,
    GetMonitoringAlarmRecord,
    SetAsRead,
    GetMonitoringAlarmSetting,
    SetMonitoringAlarmData,
    GetDisplayBasicStatisticData,
    GetBasicStatisticDataList,
    SetStatisticData,
    GetOperateRecord,
    GetWaterQualityPage,
    GetConsumablePage,
    SuppliesReset,
    GetIndustrialProcessType,
    GetUnReadNum,
    CheckOrder,
    AddOrder,
    GetDeviceControlList,
    DeviceConsumableGetList,
    GetConsumableHistory,
    GetConsumable,
    UpdateConsumable,
    SetHeadPic,
    GetUserInfo,
    GetSystemMessage,
    GetWeeklyList,
    GetConfigList,
    SetConfigInfo
}

export default apiServer