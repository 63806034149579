<template>
  <div class="RealTimeData">
    <el-dialog
      v-model="dialogVisible"
      title="实时数据展示管理"
      width="300"
      :show-close="false"
      :before-close="handleClose"
    >
      <div>
        <el-checkbox-group v-model="checkList">
          <div class="tl" v-for="item in deviceList" :key="item.deviceControlId" >
            <el-checkbox :label="item.deviceControlId" >{{ item.deviceControlName }}</el-checkbox>
          </div>
        </el-checkbox-group>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="btn" @click="dialogVisible = false">取消</el-button>
          <el-button class="btn" type="primary" @click="save">
            保存
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref,onBeforeUnmount,inject} from "vue";
import apiServer from "@/api/request";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "RealTimeData",
  props:{
    orderId:{
      type:String,
    }
  },
  setup(props,context) {
    const emitt:any = inject('emitt');
    const store = useStore();
    const dialogVisible = ref(false);
    const checkList = ref([] as any[]);//选中数据列表
    const deviceList = ref([] as any[]);//数据列表
    
   
    const handleClose = () => {
      console.log("close");
    };

    function GetRealTimeControlData(){
      context.emit('GetRealTimeControlData') 
    }
    //注册-打开弹窗
    emitt.$sub('openRealTimeData', (val:any) => {
      const value = val[0];
      
      let list = [];
      for(let item of value){
        if(item.isShow){
          list.push(item.deviceControlId)
        }
      }
      checkList.value = list;
      deviceList.value = value;
      dialogVisible.value = true;
    });

    onBeforeUnmount(() => {
      emitt.$unsub("openRealTimeData");
    });
       
    //保存
    function save(){
      const arr:object[] = [];
      deviceList.value.forEach((e:any) => {
        let obj : {deviceControlId:number,isShow:number};
        if(~checkList.value.indexOf(e.deviceControlId)){
          obj = {
            deviceControlId:e.deviceControlId,
            isShow:1
          }
        }else{
          obj = {
            deviceControlId:e.deviceControlId,
            isShow:0
          }
        }
        arr.push(obj);
      })
      

      console.log("arr",arr);

      const params = {
        controlType:1,
        showStatus:arr
      }
      const data ={ 
        orderId: props.orderId,
      }
      apiServer.SetControlShowStatus(params,data).then((res:any) => {
        console.log("SetControlShowStatus",res)
        if(res.status == 1){
          GetRealTimeControlData();
        }else{
          ElMessage.error(res.message);
        }
      })
      dialogVisible.value = false
    }

    return {
      dialogVisible,
      handleClose,
      checkList,
      deviceList,
      save
    };
  },
});
</script>

<style scoped lang="less">
.RealTimeData {
    .btn{
        width: 120px;
    }
}
</style>
