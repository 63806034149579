//用户token
export const USERTOKEN = 'USERTOKEN';
//设备列表
export const CATEGORYMODELS = 'CATEGORYMODELS';
//单位id
export const ORGANID = 'ORGANID'
//是否只读
export const ISREADONLY = 'ISREADONLY'
//分类id
export const CATEGORYID = 'CATEGORYID'
