<template>
  <div class="home"></div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { Reflector } from "three/examples/jsm/objects/Reflector.js";
import { onMounted } from "vue";
import { Mesh, DoubleSide } from "three";

export default defineComponent({
  name: "HomeView",
  components: {},
  setup() {
    const emitt: any = inject("emitt");
    emitt.$pub("refresh");

    //场景
    const scene = new THREE.Scene();
    const loader = new GLTFLoader(); //创建一个GLTF加载器
    const model = new THREE.Group(); //声明一个组对象，用来添加加载成功的三维场景
    const clock = new THREE.Clock();
    const animationMixer = new THREE.AnimationMixer(scene);
    loader.load("static/test0908.gltf", function (gltf: any) {
      const root = gltf.scene;
      scene.add(root);

      // const animationClip = gltf.animations[1];
      // const action = animationMixer.clipAction(animationClip);
      // action.play();
      // console.log("action",action.play())
      console.log("gltf", gltf);
    });
    scene.add(model); //模型对象添加到场景中

    const pointLight = new THREE.PointLight(0xffffff, 0.8);
    pointLight.position.set(700, 0, 700);
    scene.add(pointLight);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
    directionalLight.position.set(500, 500, 0);
    directionalLight.castShadow = true;
    scene.add(directionalLight);
    //环境光:没有特定方向，整体改变场景的光照明暗
    const ambient = new THREE.AmbientLight(0xffffff, 0.4);
    scene.add(ambient);

    const geometry = new THREE.PlaneGeometry(100, 100);
    //纹理贴图加载器TextureLoader
    const texLoader = new THREE.TextureLoader();
    const texture = texLoader.load("static/ground.jpeg");
    // 设置阵列
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    // uv两个方向纹理重复数量
    texture.repeat.set(60, 60); //注意选择合适的阵列数量

    const material = new THREE.MeshMatcapMaterial({
      // color: 0x00ffff,
      // 设置纹理贴图：Texture对象作为材质map属性的属性值
      map: texture, //map表示材质的颜色贴图属性
      transparent: true, // 透明度设置为 true
    });
    material.opacity = 0.8
    const mesh = new THREE.Mesh(geometry, material);
    // 旋转矩形平面
    mesh.rotateX(-Math.PI / 2);

    scene.add(mesh);


    const geometry2 = new THREE.PlaneGeometry(100, 100);
    const texLoader2 = new THREE.TextureLoader();
    const texture2 = texLoader2.load("static/background.jpeg");
    texture2.wrapS = THREE.RepeatWrapping;
    texture2.wrapT = THREE.RepeatWrapping;
    texture2.repeat.set(1, 1); //注意选择合适的阵列数量

    const material2 = new THREE.MeshMatcapMaterial({
      // color: 0x00ffff,
      // 设置纹理贴图：Texture对象作为材质map属性的属性值
      map: texture2, //map表示材质的颜色贴图属性
      transparent: true, // 透明度设置为 true
    });
    material2.opacity = 0.9
    const mesh2 = new THREE.Mesh(geometry2, material2);
    mesh2.rotateX(-Math.PI / 2);
    mesh2.translateZ(-0.8)

    scene.add(mesh2);

    let groundMirror;
    const planeGeometry = new THREE.PlaneGeometry(100, 100);
    groundMirror = new Reflector(planeGeometry, {
      clipBias: 0.003,
      textureWidth: window.innerWidth * window.devicePixelRatio,
      textureHeight: window.innerHeight * window.devicePixelRatio,
    });
    groundMirror.position.y = 0.5;
    groundMirror.rotateX(-Math.PI / 2);
    groundMirror.translateZ(-0.8)
    scene.add(groundMirror);

    
    //渲染器和相机
    const width = window.innerWidth;
    const height = window.innerHeight;
    const camera = new THREE.PerspectiveCamera(50, width / height, 1, 5000);
    // camera.position.set(200, 200, 200);//第1步：根据场景渲染范围尺寸设置
    camera.position.set(8, 10, 10); //第2步：通过相机控件辅助设置OrbitControls
    const x = 0,
      y = 1,
      z = 0; //通过OrbitControls辅助设置
    camera.lookAt(x, y, z);

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(width, height);
    // document.getElementsByClassName('home')[0].appendChild(renderer.domElement);
    //解决加载gltf格式模型颜色偏差问题
    renderer.outputEncoding = THREE.sRGBEncoding;

    // 设置相机控件轨道控制器OrbitControls
    const controls = new OrbitControls(camera, renderer.domElement);
    // 相机控件.target属性在OrbitControls.js内部表示相机目标观察点，默认0,0,0
    // console.log('controls.target', controls.target);
    controls.target.set(x, y, z); //与lookAt参数保持一致
    controls.update(); //update()函数内会执行camera.lookAt(controls.target)
    console.log("THREE", THREE, OrbitControls);
    function render() {
      // 渲染循环中不停地打印相机的位置属性，你可以通过相机控件旋转或缩放三维场景，同时通过浏览器控制台观察相机位置变化。
      // 浏览器控制台查看controls.target变化，辅助设置lookAt参数
      animationMixer.update(clock.getDelta());
      renderer.render(scene, camera);
      requestAnimationFrame(render);
    }
    render();

    // 画布跟随窗口变化
    window.onresize = function () {
      renderer.setSize(window.innerWidth, window.innerHeight);
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
    };

    onMounted(() => {
      document
        .getElementsByClassName("home")[0]
        .appendChild(renderer.domElement);
    });
  },
});
</script>
