// **与ios做交互定义的api*/
let u = navigator.userAgent
let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端

function setupWebViewJavascriptBridge(callback) {
    if (window.WebViewJavascriptBridge) { return callback(window.WebViewJavascriptBridge); }
    if (window.WVJBCallbacks) { return window.WVJBCallbacks.push(callback); }
    document.addEventListener(
        'WebViewJavascriptBridgeReady'
        , function () {
            window.WebViewJavascriptBridge.init(function (message, responseCallback) {
                responseCallback(window.data);
            });
            callback(window.WebViewJavascriptBridge)
        },
        false
    );

    window.WVJBCallbacks = [callback];
    var WVJBIframe = document.createElement('iframe');
    WVJBIframe.style.display = 'none';
    WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__';
    document.documentElement.appendChild(WVJBIframe);
    setTimeout(function () { document.documentElement.removeChild(WVJBIframe) }, 0)
}

function register(funcname, callback) {
    setupWebViewJavascriptBridge(function (bridge) {
        /*JS给ObjC提供公开的API，ObjC端通过注册，就可以在JS端调用此API时，得到回调。ObjC端可以在处理完成后，反馈给JS，这样写就是在载入页面完成时就先调用*/
        bridge.callHandler(funcname, callback)
    })
}
var Bridge = {}
Bridge.register = function (fn, callback) {
    register(fn, callback)
}

/**跳转页面 */
Bridge.routerToClient = function (arg, callback) {
    console.log(arg)
    if (typeof window.android !== 'undefined') {
        if (window.android.routerToClient)
            window.android.routerToClient(JSON.stringify(arg));

    } else if (window.WebViewJavascriptBridge) {
        window.WebViewJavascriptBridge.callHandler('routerToClient', JSON.stringify(arg), callback)
    }
}
module.exports = Bridge

