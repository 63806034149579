<template>
  <div class="DeviceComponent DeviceComponent-nopx">
    <div class="title">{{ deviceName }}</div>
    <div class="data">
      <div class="control_data">
        <div class="control_panel" v-loading="loadingFlag1">
          <div class="panel_box">
            <div class="panel_head">
              <div class="fw500">实时数据</div>
            </div>
            <div class="panel_body">
              <div class="panel_item panel_item_fl" v-for="item in deviceList" :key="item.deviceId" v-show="item.isShow">
                <div class="panel_item_top fw600 fs18" :class="item.monitorValueResult == '开启'
                  ? 'open'
                  : item.monitorValueResult == '关闭'
                    ? 'close'
                    : ''
                  ">
                  {{ item.monitorValueResult }}
                </div>
                <div class="panel_item_bottom fs14">
                  {{ item.deviceControlName }}
                </div>
              </div>
              <div class="empty1" v-if="empty1">
                <img src="https://static.nagaiot.cn/web/bigScreen/icon_nodata_white.png" alt="" />
                <div>暂无数据</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="control_data">
        <div class="panel_box mt16">
          <div class="panel_head flex_ac">
            <div class="flex flex_jl flex_ac">
              <div class="fw500 mr16">统计数据</div>
              <div class="mr16">
                <el-date-picker v-model="dateValue" type="datetimerange" :shortcuts="shortcuts" range-separator="-"
                  start-placeholder="选择时间" end-placeholder="选择时间" value-format="YYYY-MM-DD HH:mm:ss"
                  format="YYYY-MM-DD HH:mm:ss" />
              </div>
              <div>
                <el-button type="primary" @click="searchStatisticData">查询</el-button>
              </div>
            </div>
          </div>
          <div class="panel_body flex_wrap">
            <div class="panel_item panel_item2" v-for="item in displayBasicStatisticData" :key="item.id"
              v-show="item.isShow">
              <div class="panel_item_top fw600 fs20">
                {{ item.value }} {{ item.unit }}
              </div>
              <div class="panel_item_bottom fs14">{{ item.displayName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right_panel" v-loading="loadingFlag2">
      <div class="panel_box" v-for="item in controllableList" :key="item.deviceId" v-show="item.isShow">
        <div class="panel_head">
          <div class="fw500">控制-{{ item.deviceControlName }}</div>
          <div class="fs14 cs color_blue" @click="openAutomaticTriggerModal(item)">
            自动触发设置
          </div>
        </div>
        <div class="panel_body">
          <div class="panel_item">
            <div class="switch" v-if="item.dataType == 'Bit'">
              <div class="switch_btn" :class="item.monitorValue == 1 ? 'open_bg' : ''"
                @click="controllableChange(item, 1)">
                {{ item.openStatusDesc }}
              </div>
              <div class="switch_btn" :class="item.monitorValue == 0 ? 'close_bg' : ''"
                @click="controllableChange(item, 0)">
                {{ item.closeStatusDesc }}
              </div>
            </div>
            <div class="valueModel" v-else>
              <div class="value">
                {{ item.monitorValue }} <span>{{ item.dataUnit }}</span>
              </div>
              <el-button class="adjust cs" type="primary" @click="openAdjustModal(item)">
                <i class="iconfont icon-icon_btn_tz"></i> 调整
              </el-button>
            </div>
            <div class="panel">
              <div class="panel_left">
                <div class="panel_item_value" v-for="i in item.timingTriggerItems" :key="i" v-show="i.status == 1">
                  <div class="td1">
                    <div class="panel_item_title fw500 fs14">定时生效：</div>
                    <div class="fs14 tl" :class="item.dataType == 'Bit'
                      ? i.targetResultValue == 0
                        ? 'red_w'
                        : 'green_w'
                      : ''
                      ">
                      {{ i.targetResult }}
                    </div>
                  </div>
                  <div class="td2">
                    <div class="fs14 gc tl">
                      时间：{{ i.startTime?.substring(0, 5) }}
                      {{
                        i.timeType == 1 ? "" : " — " + i.endTime?.substring(0, 5)
                      }}
                    </div>
                  </div>
                  <div class="td3">
                    <div class="fs14 gc tl">循环：{{ i.cyclePeriod }}</div>
                  </div>
                </div>
              </div>
              <div class="panel_right">
                <div class="panel_item_value" v-for="i in item.conditionTriggerItems" :key="i" v-show="i.status == 1">
                  <div class="td1">
                    <div class="panel_item_title fw500 fs14">触发生效：</div>
                    <div class="fs14 tl" :class="item.dataType == 'Bit'
                      ? i.targetResultValue == 0
                        ? 'red_w'
                        : 'green_w'
                      : ''
                      ">
                      {{ i.targetResult }}
                    </div>
                  </div>
                  <div class="td2">
                    <div class="fs14 gc tl">条件：{{ i.condition }}</div>
                  </div>
                  <div class="td3">
                    <div class="fs14 gc tl">循环：{{ i.cyclePeriod }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table">
      <div class="panel_head">
        <div class="fw500">历史记录</div>
        <div class="search">
          <div class="mr16">
            <el-date-picker v-model="dateValue2" type="datetimerange" :shortcuts="shortcuts" range-separator="-"
              start-placeholder="选择时间" end-placeholder="选择时间" value-format="YYYY-MM-DD HH:mm:ss"
              format="YYYY-MM-DD HH:mm:ss" />
          </div>
          <div>
            <el-button type="primary" @click="GetMonitorHistorySearch">
              <i class="iconfont icon-icon_btn_search mr4"></i> 查询
            </el-button>
          </div>
        </div>
      </div>
      <div class="table_box">
        <el-table v-loading="tableLoding" :data="tableData" style="width: 100%; text-align: left">
          <el-table-column prop="monitorData" label="状态/值" width="180">
            <template #default="scope">
              <div class="monitorData">
                <div class="monitorStatus" :class="scope.row.status == 1 ? 'open_bg' : 'close_bg'"></div>
                <div>{{ scope.row.monitorData }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="operateTime" label="时间" />
          <el-table-column prop="operate" label="操作" />
        </el-table>
        <div class="more">查看更多</div>
      </div>
    </div>
    <AutomaticTriggerModal @GetControlList="GetControlList" :orderId="orderId" :controlList="controlList" />
    <AdjustModal :orderId="orderId" />
    <SafetyVerificationModal />
    <FooterComponent />
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, reactive, inject } from "vue";
import apiServer from "@/api/request";
import { ElMessage, ElMessageBox } from "element-plus";
import AutomaticTriggerModal from "@/components/AutomaticTriggerModal.vue";
import AdjustModal from "@/components/AdjustModal.vue";
import SafetyVerificationModal from "@/components/SafetyVerificationModal.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default defineComponent({
  name: "DeviceComponent",
  props: {
    deviceName: {},
    deviceId: {},
    deviceTypeId: {},
  },
  components: {
    AutomaticTriggerModal,
    AdjustModal,
    SafetyVerificationModal,
    FooterComponent
  },
  setup(props) {
    const emitt: any = inject("emitt");


    const deviceId = ref(props.deviceId);
    const deviceTypeId = ref(props.deviceTypeId);
    const orderId = ref(JSON.parse(localStorage.GetCategory).orderId);
    const deviceList = ref([] as any[]);
    const empty1 = ref(false);
    const dateValue = ref([] as any[]); //统计数据日历
    const displayBasicStatisticData = reactive([] as any[]);
    const controllableList = ref([] as any[]);
    const tableLoding = ref(false);
    const tableData = ref([] as any[]);
    const dateValue2 = ref(""); //历史记录日历
    const controlList = ref([] as any[]); //控制列表-所有
    const pageIndex = ref(1);
    const pageSize = ref(10);

    const loadingFlag1 = ref(false);
    const loadingFlag2 = ref(false);

    GetRealTimeControlData();
    GetControlList();
    GetMonitorHistory();
    GetDeviceControlList();
    //获取实时数据
    function GetRealTimeControlData() {
      let params = {
        orderId: orderId.value,
        deviceTypeId: deviceTypeId.value,
        monitorDeviceId: deviceId.value
      };
      loadingFlag1.value = true;
      apiServer.GetRealTimeControlData(params).then((res: any) => {
        loadingFlag1.value = false;
        if (res.status == 1) {
          if (res.data.list.length == 0) {
            empty1.value = true;
          } else {
            deviceList.value = res.data.list;
            empty1.value = false;
          }
        } else {
          empty1.value = true;
        }
      });
    }

    const shortcuts = [
      {
        text: "上周",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          return [start, end];
        },
      },
      {
        text: "上个月",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          return [start, end];
        },
      },
      {
        text: "前三个月",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          return [start, end];
        },
      },
    ];


    //查询统计数据
    function searchStatisticData() {
      if (dateValue.value == null) {
        ElMessage.error("时间不能为空");
        return;
      }
      // countStatisticData(displayBasicStatisticData, basicStatisticDataList);
    }

    //查看监控设备列表 -- 可控制的
    function GetControlList() {
      const params = {
        orderId: orderId.value,
        deviceTypeId: deviceTypeId.value,
        monitorDeviceId: deviceId.value
      };
      apiServer.GetControlList(params).then((res: any) => {
        if (res.status == 1) {
          if (res.data.list.length != 0) {
            controllableList.value = res.data.list;
          }
        } else if (res.status == 3 || res.status == 4) {
          ElMessage.error(res.message);
        } else {
          ElMessage.error(res.message);
        }
      });
    }

    //自动触发设置
    function openAutomaticTriggerModal(e: object) {
      emitt.$pub("openAutomaticTriggerModal", e);
    }

    //控制设备
    function controllableChange(item: any, flag: number) {
      if(flag == item.monitorValue) return;
      ElMessageBox.confirm(
        `是否${flag == 1 ? item.openStatusDesc : item.closeStatusDesc}${item.deviceControlName
        }？`,
        "设备操作提示",
        {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          if (item.checkMethod.toString() == "2" || item.checkMethod.toString() == "3") {
            const func = {
              method: WriteData,
              item: item,
            }

            emitt.$pub("openSafetyVerificationModal", func);
          }else{
            WriteData(item,'')
          }

        })
        .catch(() => {
          console.log("catch");
        });
    }

    const WriteData = function (item: any,code:any) {
      const params = {
        deviceControlId: item.deviceControlId,
        monitorValue: item.monitorValue == 1 ? 0 : 1,
        code
      };
      const data = {
        orderId: orderId.value,
      };
      apiServer.WriteData(params, data).then((res: any) => {
        console.log("WriteData", res, params, data);
        if (res.status == 1) {
          ElMessage.success(
            `${item.monitorValue == 0 ? item.openStatusDesc : item.closeStatusDesc}${item.deviceName
            }成功`
          );
          item.monitorValue = item.monitorValue == 1 ? 0 : 1
        } else {
          ElMessage.error(res.message);
        }
      });
    }

    function GetMonitorHistorySearch() {
      pageIndex.value = 1;
      GetMonitorHistory();
    }

    //调整值
    function openAdjustModal(e: any) {
      emitt.$pub("openAdjustModal", e);
    }
    //获取历史记录
    function GetMonitorHistory() {
      tableLoding.value = true;
      tableData.value = [];
      const params = {
        pageIndex: 1,
        pageSize: 10,
        orderId: orderId.value,
        startTime: dateValue2.value ? dateValue2.value[0] : "",
        endTime: dateValue2.value ? dateValue2.value[1] : "",
        deviceControlId: deviceId.value
      };
      apiServer.GetMonitorHistory(params).then((res: any) => {
        tableLoding.value = false;
        if (res.status == 1) {
          for (const item of res.data.list) {
            tableData.value.push(item);
          }
        }
      });
    }

    //查看监控设备列表 -- 所有的
    function GetDeviceControlList() {
      const params = {
        orderId: orderId.value,
        deviceTypeId: deviceTypeId.value,

      };
      apiServer.GetDeviceControlList(params).then((res: any) => {
        console.log("GetMonitorDeviceListall", res);
        if (res.status == 1) {
          if (res.data.list.length != 0) {
            controlList.value = res.data.list;
          }
        }
      });
    }

    return {
      deviceList,
      loadingFlag1,
      empty1,
      dateValue,
      shortcuts,
      searchStatisticData,
      displayBasicStatisticData,
      loadingFlag2,
      controllableList,
      openAutomaticTriggerModal,
      controllableChange,
      openAdjustModal,
      GetControlList,
      orderId,
      tableLoding,
      tableData,
      dateValue2,
      controlList,
      GetMonitorHistorySearch,
    };
  },
});
</script>

<style lang="less" scoped>
.DeviceComponent-nopx {
  height: calc(100vh - 123px);
  overflow-y: scroll;
  padding-bottom: 40px;

  .title {
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    padding: 0 24px;
  }

  .data {
    display: flex;
    justify-content: space-between;
    padding: 0 24px;

    .control_data {
      width: 49%;
      background: white;
      border-radius: 8px;
      margin: 16px 0;
      overflow: hidden;
    }

    .control_panel {
      background: white;

      .panel_box {
        .panel_head {
          padding: 0 16px;
          height: 52px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #e7e7e7;

        }

        .panel_body {
          padding: 24px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          min-height: 100px;

          .panel_item {
            width: 170px;
            border-radius: 4px;

            .panel_item_bottom {
              margin-top: 7px;
              color: #424248;
            }

            .open {
              color: #099e69;
            }

            .close {
              color: #ff0000;
            }
          }

          .empty1 {
            width: 112px;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            align-items: center;
            margin: 0 auto;

            img {
              width: 60px;
            }
          }
        }
      }
    }


    .panel_box {
      background: white;
      border-radius: 8px;
      overflow: hidden;

      .panel_head {
        padding: 0 16px;
        height: 52px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e7e7e7;
      }

      .panel_body {
        background: white;
        padding: 24px;
        min-height: 100px;

        .panel_item {
          padding: 15px 16px;
          padding-bottom: 20px;
          border-bottom: 1px solid #e7e7e7;
        }
      }
    }
  }

  .right_panel {
    margin: 16px 0;
    padding: 0 24px;

    .panel_box {
      background: white;
      border-radius: 8px;
      margin-bottom: 24px;

      .panel_head {
        padding: 15px 16px;
        text-align: left;
        border-bottom: 1px solid #E7E7E7;
        display: flex;
        justify-content: space-between;

        .color_blue {
          color: #2074FF;
        }
      }

      .panel_body {
        padding: 24px 16px 30px 16px;
      }

      .switch {
        display: flex;
        justify-content: left;

        .switch_btn {
          width: 80px;
          height: 32px;
          line-height: 32px;
          font-size: 14px;
          background: #F5F7F9;
          cursor: pointer;

          &:first-child {
            border-right: 0;
            border-radius: 4px 0px 0px 4px;
          }

          &:last-child {
            border-radius: 0px 4px 4px 0px;
          }
        }

        .close_bg {
          background: #2074FF;
          color: white;
        }

        .open_bg {
          background: #2074FF;
          color: white;
        }
      }

      .valueModel {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        padding: 0 10px;
        border-radius: 4px;
        margin-top: 10px;
        width: 400px;

        .value {
          font-weight: 600;
          width: 320px;
          border: 1px solid #E7E7E7;
          height: 32px;
          border-radius: 4px;
          line-height: 32px;
          font-size: 14px;
          padding: 0 12px;
          text-align: left;

          span {
            font-size: 12px;
            float: right;
          }
        }

        .adjust {
          font-size: 12px;
          color: #2074ff;
          margin-left: 16px;
          color: white;

          .iconfont {
            margin-right: 4px;
          }
        }
      }

      .panel {
        display: flex;
        justify-content: left;
        margin-top: 24px;
      }

      .panel_left {
        width: 40%;
        margin-right: 5%;
      }

      .panel_right {
        width: 45%;
      }

      .panel_item_value {
        display: flex;
        justify-content: left;
        margin-bottom: 16px;

        .td1 {
          display: flex;
          justify-content: left;
          margin-right: 10%;
        }

        .td2 {
          margin-right: 10%;
        }
      }
    }
  }

  .table {
    margin: 0 24px;
    margin-top: 16px;
    border-radius: 8px;
    overflow: hidden;
    background: white;

    .panel_head {
      padding: 15px 16px;
      text-align: left;
      border-bottom: 1px solid #E7E7E7;
      display: flex;
      justify-content: space-between;

      .search {
        display: flex;
        justify-content: left;
      }

      .color_blue {
        color: #2074FF;
      }
    }

    .table_box {
      padding: 16px;

      .monitorData {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .monitorStatus {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: 8px;
      }

      .close_bg {
        background: #FF5050;
      }

      .open_bg {
        background: #13ba7f;
      }
    }
  }

  .more {
    font-size: 14px;
    color: #2074FF;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #ebeef5;
    cursor: pointer;
  }
}
</style>
<style lang="less">
.DeviceComponent-nopx {
  
}
</style>
