  export function getBrowser() {
    const rMsie = /(msie\s|trident\/7)([\w\\.]+)/;
    // const rTrident = /(trident)\/([\w.]+)/;
    const rEdge = /(chrome)\/([\w.]+)/;// IE

    const rFirefox = /(firefox)\/([\w.]+)/; // 火狐
    const rOpera = /(opera).+version\/([\w.]+)/; // 旧Opera
    // const rNewOpera = /(opr)\/(.+)/; // 新Opera 基于谷歌
    const rChrome = /(chrome)\/([\w.]+)/; // 谷歌
    const rUC = /(chrome)\/([\w.]+)/;// UC
    const rMaxthon = /(chrome)\/([\w.]+)/;// 遨游
    const r2345 = /(chrome)\/([\w.]+)/;// 2345
    const rQQ = /(chrome)\/([\w.]+)/;// QQ
    // const rMetasr = /(metasr)\/([\w.]+)/;// 搜狗
    const rSafari = /version\/([\w.]+).*(safari)/;
    const ua = navigator.userAgent.toLowerCase();
    let str = '';
    if (rMsie.exec(ua)) { //
      str = 'Microsoft IE浏览器';
    } else if (rEdge.exec(ua)) {
      str = 'Microsoft Edge浏览器';
    } else if (rUC.exec(ua)) {
      str = 'UC浏览器';
    } else if (rFirefox.exec(ua)) {
      str = '火狐浏览器';
    } else if (rOpera.exec(ua)) {
      str = 'Opera浏览器';
    } else if (rMaxthon.exec(ua)) {
      str = '遨游浏览器';
    } else if (r2345.exec(ua)) {
      str = '2345浏览器';
    } else if (rQQ.exec(ua)) {
      str = 'QQ浏览器';
    } else if (rSafari.exec(ua)) {
      str = 'Safari浏览器';
    } else if (rChrome.exec(ua)) {
      str = '谷歌浏览器';
    } else {
      str = '未知';
    }
    return str;
  }

  
  export function getQueryVariable(name: string) {
    const after = window.location.hash.split("?")[1];
    if (after) {
      const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      const r = after.match(reg);
      if (r != null) {
        return decodeURIComponent(r[2]);
      }
      else {
        return null;
      }
    } else {
      const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      const r = window.location.search.substr(1).match(reg);
      if (r != null)
        return unescape(r[2]);
      return null;
    }
  }

  // 拼接参数
  export function urlEncode (param: any = {}, key = '', encode = false) {
    if (param !== 0 && !param) {
      return '';
    }
    let paramStr = '';
    const t = typeof param;
    if (t === 'string' || t === 'number' || t === 'boolean') {
      paramStr += `&${key}=${encode ? encodeURIComponent(param) : param}`;
    } else {
      // eslint-disable-next-line no-restricted-syntax
      for (const i in param) {
        console.log("key,encode", param instanceof Array)
        const k = key == null ? i : key + (param instanceof Array ? `[${i}]` : `${i}`);

        paramStr += urlEncode(param[i], k, encode);
      }
    }

    return paramStr;
  }
