<template>
  <div class="LoginPage">
    <div class="left_box-nopx">
    </div>
    <div class="right_box-nopx">
      <div class="login_box">
        <img class="img_logo" src="https://static.nagaiot.cn/web/logo_xty.png" alt="" />
        <div class="login_input_box">
          <el-tabs v-model="activeName" class="demo-tabs">
            <el-tab-pane label="手机验证码登录" name="phone">
              <div class="input_item">
                <div class="prefix">+86</div>
                <div class="value_box">
                  <input type="text" v-model="phone" placeholder="请输入手机号" oninput="value=value.replace(/[^\d]/g,'')"
                    maxlength="11" />
                </div>
              </div>
              <div class="input_item">
                <div class="value_box">
                  <input type="text" v-model="code" placeholder="短信验证码" />
                </div>
                <div class="suffix cs" @click="GetByMobile">
                  {{ sendMessage }}
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="账号密码登录" name="account">
              <div class="input_item">
                <div class="value_box value_box2">
                  <input type="text" v-model="account" placeholder="请输入账号" />
                </div>
              </div>
              <div class="input_item">
                <div class="value_box value_box2">
                  <input :type="eyesFlag ? 'password' : 'text'" placeholder="请输入8-15位字母数字组合密码" v-model="password" />
                  <img class="icon_eye cs" v-if="!eyesFlag"
                    src="https://static.nagaiot.cn/web/icon_eye_yes_light.png" alt=""
                    @click="eyesFlag = true" />
                  <img class="icon_eye cs" v-else
                    src="https://static.nagaiot.cn/web/icon_eye_no_dack.png" alt=""
                    @click="eyesFlag = false" />
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
          <div class="login_btn">
            <el-button class="login" type="primary" @click="Login">登录</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { ElMessage } from "element-plus";
import apiService from "@/api/request";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "LoginPage",
  setup() {
    const router = useRouter();
    const store = useStore();

    const activeName = ref("phone");
    const eyesFlag = ref(true);
    const phone = ref(""); //手机号
    const code = ref(""); //验证码
    const account = ref(""); //账号
    const password = ref(""); //密码
    const sendMessage = ref("获取验证码");

    //发送验证码
    let timer: number | null = null;
    const time = ref(60);
    function GetByMobile() {
      if (phone.value == "") {
        ElMessage.error("请输入您的手机号码");
        return;
      }
      if (phone.value.length !== 11) {
        ElMessage.error("请输入正确的手机格式");
        return;
      }
      if (timer) return;
      try {
        var captcha = new window.TencentCaptcha("197132060", sendCodeFunc, {
          userLanguage: "cn",
          needFeedBack: false,
        });
        captcha.show();
      } catch (error) {
        loadErrorCallback(error);
      }

      function sendCodeFunc(result: any) {
        console.log("result", result)
        if (result.ret != 0) return;
        sendMessage.value = "正在发送";
        const params = {
          smsType: 31,
          phone: window.btoa(phone.value),
          mobileAreaCode: "+86",
          ticket: result.ticket,
          randstr: result.randstr
        };
        apiService.GetByMobile(params).then((res: any) => {
          if (res.status == 1) {
            sendMessage.value = `${time.value}S`;
            timer = setInterval(() => {
              sendMessage.value = `${time.value}S`;
              time.value -= 1;
              if (time.value <= 0) {
                clearInterval(timer as number);
                sendMessage.value = "重新发送";
                time.value = 60;
                timer = null;
              }
            }, 1000);
          } else {
            ElMessage.error(res.message);
            sendMessage.value = "获取验证码";
          }
        });
      }

      function loadErrorCallback(res: any) {
        var appid = "190327966";
        // 生成容灾票据或自行做其它处理
        var ticket =
          "terror_1001_" +
          appid +
          "_" +
          Math.floor(new Date().getTime() / 1000);
        callback({
          ret: 0,
          randstr: "@" + Math.random().toString(36).substr(2),
          ticket: ticket,
          errorCode: 1001,
          errorMessage: "jsload_error",
        });
      }

      function callback(res: any) {
        console.log("callback:", res);
        if (res.ret === 0) {
          console.log(res);
        }
      }
    }

    //登陆
    function Login() {
      if (activeName.value == "phone") {
        if (phone.value == "") {
          ElMessage.error("请输入您的手机号码");
          return;
        }
        if (code.value == "") {
          ElMessage.error("请输入验证码");
          return;
        }
        const params = {
          phone: window.btoa(phone.value),
          mobileAreaCode: "+86",
          vcode: code.value,
        };
        apiService.OneKeyLoginVcode(params).then((res: any) => {
          if (res.status == 1) {
            ElMessage.success("成功");
            store.commit("USERTOKEN", res.data.userToken);
            localStorage.userInfo = JSON.stringify(res.data.userInfo);
            router.push("/");
          } else {
            ElMessage.error(res.message);
          }
        });
      } else {
        console.log("password", password.value);
        if (account.value == "") {
          ElMessage.error("请输入您的手机号码");
          return;
        }
        if (password.value == "") {
          ElMessage.error("请输入密码");
          return;
        }
        const params = {
          account: window.btoa(account.value),
          password: window.btoa(password.value),
        };
        apiService.Login(params).then((res: any) => {
          if (res.status == 1) {
            ElMessage.success("成功");
            store.commit("USERTOKEN", res.data.userToken);
            localStorage.userInfo = JSON.stringify(res.data.userInfo);
            router.push("/");
          } else {
            ElMessage.error(res.message);
          }
        });
      }
    }

    return {
      activeName,
      eyesFlag,
      phone,
      code,
      account,
      password,
      sendMessage,
      GetByMobile,
      Login,
    };
  },
});
</script>

<style scoped lang="less">
.LoginPage {
  display: flex;
  justify-content: left;
  background: white;
  min-width: 1280px;

  .left_box-nopx {
    background: url("https://static.nagaiot.cn/web/bgleft.png") no-repeat;
    width: 480px;
    height: 100vh;
    background-size: 100% 100%;

    img {
      height: 100%;
    }
  }

  .right_box-nopx {
    width: calc(100vw - 480px);
    min-width: 800px;
    position: relative;

    .login_box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 430px;
      padding-bottom: 100px;

      .img_logo {
        width: 90px;
        height: 90px;
      }

      .login_input_box {
        box-shadow: 0px 0px 30px 10px rgba(234, 241, 248, 0.5);
        border: 1px solid #e5eaee;
        margin-top: 40px;
        padding: 25px 42px;
        padding-bottom: 58px;
        border-radius: 4px;

        .input_item {
          height: 48px;
          background: #fbfbfb;
          border: 1px solid #e3e6e8;
          display: flex;
          justify-content: left;
          border-radius: 4px;

          .prefix {
            width: 86px;
            text-align: left;
            padding-left: 16px;
            line-height: 22px;
            font-size: 14px;
            border-right: 1px solid #e3e6e8;
            margin: 12px 0;
          }

          .value_box {
            line-height: 46px;
            padding-left: 10px;
            width: 243px;
            text-align: left;

            input {
              font-size: 14px;
              background: #fbfbfb;
            }
          }

          &:first-child {
            margin-top: 30px;
          }

          &:nth-child(2) {
            margin-top: 24px;
          }

          .suffix {
            line-height: 22px;
            border-left: 1px solid #e3e6e8;
            margin: 12px 0;
            padding-left: 16px;
            font-size: 14px;
            color: #2074ff;
          }

          .value_box2 {
            width: 100%;
            position: relative;

            input {
              width: 100%;
            }

            .icon_eye {
              position: absolute;
              right: 16px;
              top: 16px;
              width: 16px;
              height: 16px;
            }
          }
        }

        .login_btn {
          margin-top: 40px;

          .login {
            width: 100%;
            height: 48px;
            font-size: 16px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.LoginPage {
  .el-tabs__nav {
    width: 100%;
    justify-content: center;
  }

  .el-tabs__item {
    font-weight: 600;
    font-size: 16px;
  }

  .el-tabs__item.is-active {
    color: #000000;
    font-weight: 500;
  }

  .el-tabs__nav-wrap::after {
    display: none;
  }

  .el-button>span {
    font-weight: 600;
  }
}
</style>
