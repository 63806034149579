export default{
    timeout: 30000,
  //测试域名
  testBaseUrl: {
    http: 'https://user.nagaiot.cn/',
    controlhttp: 'http://192.168.60.191:3000/',
    // http:'https://userdev.nagaiot.cn',
    // controlhttp: 'https://control.nagaiot.cn/',
  },
  //线上域名
  masterBaseUrl: {
    http: 'https://user.nagaiot.cn/',
    controlhttp: 'https://control.nagaiot.cn/',
  },
  //本地
  loaclBaseUrl: {
    http:'',
    controlhttp: '',
    
  },
  //加密解密信息
  key:'bb2e6e5d006552c0',
  iv:'a996c9d194f67d8b'
}



