<template>
  <div class="IndexPage">
    <HeaderNav></HeaderNav>
      <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HeaderNav from "@/components/HeaderNav.vue";

export default defineComponent({
  name: "IndexPage",
  components: { HeaderNav },
  setup() {
    return {};
  },
});
</script>

<style scoped lang="less"></style>
