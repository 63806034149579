<template>
  <div class="MonitoringPanel">
    <div class="home"></div>
    <div class="my-head">
      <img src="https://static.nagaiot.cn/web/bigScreen/logo_xty.png" alt="" />
      <span class="my-title">鑫泰云—{{ OverallOperationalStatus.productName }}</span>
      <span class="my-time">{{ thisTime }}</span>
    </div>
    <div class="left-t">
      <div class="left-t-title">原水箱水位</div>
      <div class="water-tank">
        <div class="water" :style="{ 'height': OverallOperationalStatus.rawWaterLevel + '%' }">
        </div>
        <div class="value">{{ OverallOperationalStatus.rawWaterLevel }}%</div>
      </div>
    </div>
    <div class="left-r">
      <div class="left-t-title">纯水箱水位</div>
      <div class="water-tank">
        <div class="water" :style="{ 'height': OverallOperationalStatus.pureWaterLevel + '%' }">
        </div>
        <div class="value">{{ OverallOperationalStatus.pureWaterLevel }}%</div>
      </div>
    </div>
    <div class="center">
      <div class="center-item">
        <div class="center-value" :class="OverallOperationalStatus.statusDesc == '运行中' ? 'green_w' : 'red_w'">{{
          OverallOperationalStatus.statusDesc }}</div>
        <div class="center-label">状态</div>
      </div>
      <div class="center-item">
        <div class="center-value">{{ OverallOperationalStatus.instantaneousFlow }}</div>
        <div class="center-label">瞬时流量</div>
      </div>
      <div class="center-item">
        <div class="center-value">{{ OverallOperationalStatus.totalFlow }}</div>
        <div class="center-label">累计流量</div>
      </div>
      <div class="center-item">
        <div class="center-value">{{ OverallOperationalStatus.rawWater }}</div>
        <div class="center-label">原水电导率</div>
      </div>
      <div class="center-item">
        <div class="center-value">{{ OverallOperationalStatus.roWater }}</div>
        <div class="center-label">RO电导率</div>
      </div>
      <div class="center-item">
        <div class="center-value">{{ OverallOperationalStatus.pureWater }}</div>
        <div class="center-label">纯水电导率</div>
      </div>
    </div>
    <div class="system-log">
      <div class="left-t-title">系统日志</div>
      <div class="list-log" ref="roll">
        <div class="item-log" v-for="item in logDataList" :key="item.operateContent">
          <div class="time-log">{{ item.time }}</div>
          <div>{{ item.operateContent }}</div>
        </div>

      </div>
    </div>
    <div class="monitor" id="monitor">
      <div id="video-container"></div>
    </div>
    <div class="left-m">
      <div class="left-t-title">报警记录</div>
      <div class="list-s">
        <div class="list" ref="tableRef">
          <div class="item" v-for="item of alarmDataList" :key="item.id">
            <div class="item-top">
              <div class="item-title">{{ item.title }}</div>
              <div class="item-time">{{ item.createTime }}</div>
            </div>
            <div class="item-bottom">
              <div class="item-value">{{ item.currentValue }}</div>
              <div class="item-warn">{{ item.warnValue }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-bottom">
      <div class="my-echar">
        <div class="my-echar-title">耗电量 (KWH)</div>
        <div id="power-consumption"></div>
      </div>
      <div class="my-echar">
        <div class="my-echar-title">每日流量 (m³)</div>
        <div id="flow"></div>
      </div>
      <div class="my-echar">
        <div class="my-echar-title">纯水PH值</div>
        <div id="ph"></div>
      </div>
      <div class="my-echar">
        <div class="my-echar-title">纯水浊度</div>
        <div id="turbidity"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, nextTick } from "vue";
import * as THREE from "three";
import * as echarts from "echarts";
import { useRouter } from "vue-router";
import apiServer from "@/api/request";
import { ElMessage } from "element-plus";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { Reflector } from "three/examples/jsm/objects/Reflector.js";

export default defineComponent({
  name: "MonitoringPanel",
  setup() {
    const router = useRouter();
    const orderId = router.currentRoute.value.query.orderId;

    //场景
    const scene = new THREE.Scene();
    const loader = new GLTFLoader(); //创建一个GLTF加载器
    const model = new THREE.Group(); //声明一个组对象，用来添加加载成功的三维场景
    const clock = new THREE.Clock();
    const animationMixer = new THREE.AnimationMixer(scene);
    loader.load("static/test0908.gltf", function (gltf: any) {
      const root = gltf.scene;
      scene.add(root);

      // const animationClip = gltf.animations[1];
      // const action = animationMixer.clipAction(animationClip);
      // action.play();
      // console.log("action",action.play())
      console.log("gltf", gltf);
    });
    scene.add(model); //模型对象添加到场景中

    const pointLight = new THREE.PointLight(0xffffff, 0.8);
    pointLight.position.set(700, 0, 700);
    scene.add(pointLight);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
    directionalLight.position.set(500, 500, 0);
    directionalLight.castShadow = true;
    scene.add(directionalLight);
    //环境光:没有特定方向，整体改变场景的光照明暗
    const ambient = new THREE.AmbientLight(0xffffff, 0.4);
    scene.add(ambient);

    const geometry = new THREE.PlaneGeometry(100, 100);
    //纹理贴图加载器TextureLoader
    const texLoader = new THREE.TextureLoader();
    const texture = texLoader.load("static/ground.jpeg");
    // 设置阵列
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    // uv两个方向纹理重复数量
    texture.repeat.set(60, 60); //注意选择合适的阵列数量

    const material = new THREE.MeshMatcapMaterial({
      // color: 0x00ffff,
      // 设置纹理贴图：Texture对象作为材质map属性的属性值
      map: texture, //map表示材质的颜色贴图属性
      transparent: true, // 透明度设置为 true
    });
    material.opacity = 0.8;
    const mesh = new THREE.Mesh(geometry, material);
    // 旋转矩形平面
    mesh.rotateX(-Math.PI / 2);

    scene.add(mesh);

    const geometry2 = new THREE.PlaneGeometry(100, 100);
    const texLoader2 = new THREE.TextureLoader();
    const texture2 = texLoader2.load("static/background.jpeg");
    texture2.wrapS = THREE.RepeatWrapping;
    texture2.wrapT = THREE.RepeatWrapping;
    texture2.repeat.set(1, 1); //注意选择合适的阵列数量

    const material2 = new THREE.MeshMatcapMaterial({
      // color: 0x00ffff,
      // 设置纹理贴图：Texture对象作为材质map属性的属性值
      map: texture2, //map表示材质的颜色贴图属性
      transparent: true, // 透明度设置为 true
    });
    material2.opacity = 0.9;
    const mesh2 = new THREE.Mesh(geometry2, material2);
    mesh2.rotateX(-Math.PI / 2);
    mesh2.translateZ(-0.8);

    scene.add(mesh2);

    let groundMirror;
    const planeGeometry = new THREE.PlaneGeometry(100, 100);
    groundMirror = new Reflector(planeGeometry, {
      clipBias: 0.003,
      textureWidth: window.innerWidth * window.devicePixelRatio,
      textureHeight: window.innerHeight * window.devicePixelRatio,
    });
    groundMirror.position.y = 0.5;
    groundMirror.rotateX(-Math.PI / 2);
    groundMirror.translateZ(-0.8);
    scene.add(groundMirror);

    //渲染器和相机
    const width = window.innerWidth;
    const height = window.innerHeight < 1006 ? 1006 : window.innerHeight;
    const camera = new THREE.PerspectiveCamera(50, width / height, 1, 5000);
    // camera.position.set(200, 200, 200);//第1步：根据场景渲染范围尺寸设置
    camera.position.set(5, 5, 6); //第2步：通过相机控件辅助设置OrbitControls
    const x = 0,
      y = 1,
      z = 0; //通过OrbitControls辅助设置
    camera.lookAt(x, y, z);

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(width, height);
    // document.getElementsByClassName('home')[0].appendChild(renderer.domElement);
    //解决加载gltf格式模型颜色偏差问题
    renderer.outputEncoding = THREE.sRGBEncoding;

    // 设置相机控件轨道控制器OrbitControls
    const controls = new OrbitControls(camera, renderer.domElement);
    // 相机控件.target属性在OrbitControls.js内部表示相机目标观察点，默认0,0,0
    // console.log('controls.target', controls.target);
    controls.target.set(x, y, z); //与lookAt参数保持一致
    controls.update(); //update()函数内会执行camera.lookAt(controls.target)
    console.log("THREE", THREE, OrbitControls);
    function render() {
      // 渲染循环中不停地打印相机的位置属性，你可以通过相机控件旋转或缩放三维场景，同时通过浏览器控制台观察相机位置变化。
      // 浏览器控制台查看controls.target变化，辅助设置lookAt参数
      animationMixer.update(clock.getDelta());
      renderer.render(scene, camera);
      requestAnimationFrame(render);
    }
    render();

    // 画布跟随窗口变化
    window.onresize = function () {
      renderer.setSize(window.innerWidth, window.innerHeight);
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
    };

    //耗电柱状图
    function makePowerConsumptionEchar(time: any, statisticValue: any) {
      const chartDom = document.getElementById("power-consumption");
      const myChart = echarts.init(chartDom);
      let option = {
        grid: {
          left: "0%",
          right: "0%",
          bottom: "0%",
          top: "12%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: time,
            axisLine: {
              lineStyle: {
                color: "#5C7AAB",
              },
            },
            axisLabel: {
              color: "#89A0BE",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: "#89A0BE",
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#3F526B",
              },
            },
          },
        ],
        series: [
          {
            name: "Direct",
            type: "bar",
            barWidth: "40%",
            data: statisticValue,
            label: {
              show: true,
              position: "top",
              textStyle: {
                fontSize: "12px",
                color: "#20DFFF",
              },
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#1EB9F8" },
                { offset: 1, color: "#2967FA" },
              ]),
            },
          },
        ],
      };
      myChart.setOption(option);
    }

    //每日流量柱状图
    function makeFlowEchar(time: any, statisticValue: any) {
      const chartDom = document.getElementById("flow");
      const myChart = echarts.init(chartDom);
      let option = {
        grid: {
          left: "0%",
          right: "0%",
          bottom: "0%",
          top: "12%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: time,
            axisLine: {
              lineStyle: {
                color: "#5C7AAB",
              },
            },
            axisLabel: {
              color: "#89A0BE",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: "#89A0BE",
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#3F526B",
              },
            },
          },
        ],
        series: [
          {
            name: "",
            type: "bar",
            barWidth: "40%",
            label: {
              show: true,
              position: "top",
              textStyle: {
                fontSize: "12px",
                color: "#FF4F4F",
              },
            },
            data: statisticValue,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#FF5858" },
                { offset: 1, color: "#8E1010" },
              ]),
            },
          },
        ],
      };
      myChart.setOption(option);
    }

    //PH值折线图
    function makePHEchar(time: any, statisticValue: any) {
      const chartDom = document.getElementById("ph");
      const myChart = echarts.init(chartDom);
      const option = {
        grid: {
          left: "0%",
          right: "0%",
          bottom: "0%",
          top: "12%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: time,
          axisLine: {
            lineStyle: {
              color: "#5C7AAB",
            },
          },
          axisLabel: {
            color: "#89A0BE",
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#89A0BE",
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#3F526B",
            },
          },
        },
        series: [
          {
            data: statisticValue,
            type: "line",
            symbol: "circle",
            symbolSize: [8, 8],
            label: { show: true, color: "#20DFFF" },
            lineStyle: { color: "#20DFFF" },
            itemStyle: {
              color: "#20DFFF",
            },
          },
        ],
      };
      myChart.setOption(option);
    }

    //浊度折线图
    function makeTurbidityEchar(time: any, statisticValue: any) {
      const chartDom = document.getElementById("turbidity");
      const myChart = echarts.init(chartDom);
      const option = {
        grid: {
          left: "0%",
          right: "0%",
          bottom: "0%",
          top: "12%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: time,
          axisLine: {
            lineStyle: {
              color: "#5C7AAB",
            },
          },
          axisLabel: {
            color: "#89A0BE",
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#89A0BE",
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#3F526B",
            },
          },
        },
        series: [
          {
            data: statisticValue,
            type: "line",
            symbol: "circle",
            symbolSize: [8, 8],
            label: { show: true, color: "#D9AC5E" },
            lineStyle: { color: "#D9AC5E" },
            itemStyle: {
              color: "#D9AC5E",
            },
          },
        ],
      };
      myChart.setOption(option);
    }

    const OverallOperationalStatus = reactive({
      productName: '',
      pureWater: '',
      pureWaterLevel: '',
      rawWater: '',
      rawWaterLevel: '',
      roWater: '',
      statusDesc: '',
      totalFlow: '',
      instantaneousFlow: '',
    })



    const alarmDataList = ref([] as any[])
    const logDataList = ref([] as any[])
    GetOverallOperationalStatus();
    GetMonitoringAlarmRecord();
    GetDeviceMonitorLog();

    function GetMonitoringAlarmRecord() {
      const params = {
        orderId,
        pageIndex: 1,
        pageSize: 10
      }

      apiServer.GetMonitoringAlarmRecord(params).then((res: any) => {
        console.log("GetMonitoringAlarmRecord", res);
        if (res.status == 1) {
          alarmDataList.value = res.data.list
        } else {
          ElMessage.error(res.message)
        }
      });
    }


    //查询大屏运行状态
    function GetOverallOperationalStatus() {
      const params = {
        orderId
      }
      apiServer.GetOverallOperationalStatus(params).then((res: any) => {
        console.log("GetOverallOperationalStatus", res)
        if (res.status == 1) {
          Object.assign(OverallOperationalStatus, res.data)
        }
      })

    }
    //系统日志
    function GetDeviceMonitorLog() {
      const params = {
        orderId,
        limit: 10
      } 
      apiServer.GetDeviceMonitorLog(params).then((res: any) => {
        if (res.status == 1) {
          logDataList.value = res.data.list
        }
      })
    }


    //查询数据
    function GetChartStatistic() {
      const params = {
        orderId,
      }
      apiServer.GetChartStatistic(params).then((res: any) => {
        if (res.status == 1) {
          console.log(res)
          makePowerConsumptionEchar(res.data.energyConsumption.time, res.data.energyConsumption.statisticValue)
          makePHEchar(res.data.purePhData.time, res.data.purePhData.statisticValue);
          makeFlowEchar(res.data.purifiedWater.time, res.data.purifiedWater.statisticValue);
          makeTurbidityEchar(res.data.pureWater.time, res.data.pureWater.statisticValue);
        }
      })
    }

    const cameraAddress = ref('');



    //查询摄像头地址
    function GetCameraAddress(width: any, height: any) {
      const params = {
        orderId
      }
      console.log(width, height);
      apiServer.GetCameraAddress(params).then((res: any) => {
        if (res.status == 1) {
          cameraAddress.value = res.data.url
          var player = new window.EZUIKit.EZUIKitPlayer({
            id: 'video-container', // 视频容器ID
            accessToken: res.data.accessToken,
            url: res.data.url,
            width: width,
            height: height,
            handleError: (err: any) => {
              if (err.type === "handleRunTimeInfoError" && err.data.nErrorCode === 5) {
                // 加密设备密码错误
              }
            },
          })

        }
      })
    }

    const thisTime = ref('')
    currentTime();
    setInterval(() => {
      currentTime();
    }, 1000)
    function currentTime() {
      var currentTime = new Date();

      // 提取年、月、日、小时、分钟和秒数
      const year = currentTime.getFullYear(); // 获取完整的四位年份
      const month = currentTime.getMonth() + 1; // 注意月份从0开始计算，所以需要加1
      const day = currentTime.getDate();
      const hour = currentTime.getHours();
      const minute = currentTime.getMinutes();
      const second = currentTime.getSeconds();

      thisTime.value = year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second
    }

    const tableRef = ref<null | HTMLElement>(null);
    const roll = ref<null | HTMLElement>(null);
    const timer = ref<null | number>(null)

    const MarqueeTest = () => {
      let scroll = tableRef.value
      let scroll2 = roll.value
      if (scroll !== null) {
        //组件进行滚动
        scroll.scrollTop += 1
        // console.log(scroll.scrollTop,scroll.scrollHeight, scroll.clientHeight)
        //判断滚动条是否滚动到底部
        if (scroll.scrollTop == scroll.scrollHeight - scroll.clientHeight) {
          //获取组件第一个节点
          let firstNode = scroll.childNodes[0]
          // //删除节点
          scroll.removeChild(firstNode)
          //将该节点拼接到组件最后
          scroll.append(firstNode)
        }
      }
      if (scroll2 !== null) {
        //组件进行滚动
        scroll2.scrollTop += 1
        // console.log(scroll.scrollTop,scroll.scrollHeight, scroll.clientHeight)
        //判断滚动条是否滚动到底部
        if (scroll2.scrollTop == scroll2.scrollHeight - scroll2.clientHeight) {
          //获取组件第一个节点
          let firstNode = scroll2.childNodes[0]
          // //删除节点
          scroll2.removeChild(firstNode)
          //将该节点拼接到组件最后
          scroll2.append(firstNode)
        }
      }
    }
    //开启定时器方法
    const start = () => {
      //定时器触发周期
      let speed = ref(40)
      //setInterval会返回一个id,clearTimeout拿到该id才可清除
      timer.value = setInterval(MarqueeTest, speed.value)
    }
    onMounted(() => {
      document
        .getElementsByClassName("home")[0]
        .appendChild(renderer.domElement);
      GetChartStatistic();
      let element = document.getElementById('monitor');
      let width = element?.offsetWidth;
      let height = element?.offsetHeight;
      // GetCameraAddress(width,height);

      nextTick(() => {
        start()
      })



    });
    return {
      OverallOperationalStatus,
      alarmDataList,
      logDataList,
      cameraAddress,
      thisTime,
      tableRef,
      roll
    };
  },
});
</script>

<style scoped lang="less">
.MonitoringPanel {
  position: relative;
  min-height: 1006px;
  overflow-x: hidden;

  .my-head {
    width: 100%;
    height: 140px;
    background: url("https://static.nagaiot.cn/web/bigScreen/title_top.png") no-repeat;
    background-size: 100%;
    position: absolute;
    top: 0;
    text-align: center;
    padding-top: 12px;

    .my-title {
      font-size: 30px;
      font-family: FZLTCHK--GBK1, FZLTCHK--GBK1;
      color: #00ffff;
      line-height: 35px;
    }

    img {
      position: absolute;
      left: 16px;
      top: 10px;
      width: 92px;
      height: 28px;
    }

    .my-time {
      position: absolute;
      right: 24px;
      top: 15px;
      font-size: 12px;
      color: #ffffff;
    }
  }

  .left-t {
    width: 360px;
    height: 235px;
    position: absolute;
    left: 16px;
    top: 68px;
    background: url(https://static.nagaiot.cn/web/bigScreen/title_2.png) no-repeat;
    background-size: 100%;

    .water-tank {
      width: 192px;
      height: 176px;
      background: url(https://static.nagaiot.cn/web/bigScreen/pic_sx.png) no-repeat;
      margin: 20px auto 0 auto;
      position: relative;

      .value {
        color: #FFFFFF;
        position: relative;
        top: -10px;
      }

      .water {
        width: 180px;
        background: url(https://static.nagaiot.cn/web/bigScreen/white_bg_yellow.png) no-repeat;
        margin: 0 auto;
        position: absolute;
        bottom: 6px;
        left: 6px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        background-size: cover;
      }
    }
  }

  .left-r {
    width: 360px;
    height: 235px;
    position: absolute;
    right: 16px;
    top: 68px;
    background: url(https://static.nagaiot.cn/web/bigScreen/title_2.png) no-repeat;
    background-size: 100%;

    .water-tank {
      width: 192px;
      height: 176px;
      background: url(https://static.nagaiot.cn/web/bigScreen/pic_sx.png) no-repeat;
      margin: 20px auto 0 auto;
      position: relative;

      .value {
        color: #FFFFFF;
        position: relative;
        top: -10px;
      }

      .water {
        width: 180px;
        height: 100%;
        background: url(https://static.nagaiot.cn/web/bigScreen/white_bg_blue.png) no-repeat;
        margin: 0 auto;
        position: absolute;
        bottom: 6px;
        left: 6px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        background-size: cover;
      }
    }
  }

  .center {
    width: 940px;
    height: 100px;
    background: url(https://static.nagaiot.cn/web/bigScreen/bg_zt.png) no-repeat;
    background-size: 100%;
    position: absolute;
    top: 82px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .center-item {
      width: 140px;

      .center-value {
        font-size: 18px;
        color: #DDEBF4;
      }

      .green {
        color: #00FF00;
      }

      .center-label {
        font-size: 16px;
        color: #89A0BE;
        margin-top: 9px;
      }
    }
  }

  .system-log {
    width: 360px;
    height: 216px;
    position: absolute;
    right: 16px;
    top: 325px;
    background: url(https://static.nagaiot.cn/web/bigScreen/title_2.png) no-repeat;
    background-size: 100%;

    .list-log {
      height: 180px;
      overflow: hidden;
    }

    .item-log {
      height: 45px;
      text-align: left;
      padding-left: 20px;
      margin-top: 10px;
      position: relative;

      .time-log {
        color: #89a0be;
        margin-bottom: 8px;
      }

      font-size: 14px;
      color: #ddebf4;

      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 8px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #1A8DF0;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 2px;
        top: 13px;
        width: 1px;
        background: #1A8DF0;
        height: calc(100% + 10px)
      }

      &:last-child {
        &::before {
          display: none;
        }
      }
    }
  }

  .monitor {
    width: 360px;
    height: 164px;
    position: absolute;
    right: 16px;
    top: 561px;
    background: url(https://static.nagaiot.cn/web/bigScreen/bg_jk.png) no-repeat;
    background-size: 100%;

    #live {
      width: 100%;
      height: 100%;
    }
  }

  .left-m {
    width: 360px;
    height: 380px;
    position: absolute;
    left: 16px;
    top: 325px;
    background: url(https://static.nagaiot.cn/web/bigScreen/title_2.png) no-repeat;
    background-size: 100%;

    .list-s {
      .list {
        height: 324px;
        overflow: hidden;

        .item {
          margin-top: 12px;
          padding: 10px 16px;
          background: rgba(76, 105, 135, 0.3);
          color: #ddebf4;

          .item-top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .item-title {
              font-size: 16px;
            }

            .item-time {
              font-size: 12px;
            }
          }

          .item-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            margin-top: 12px;
          }
        }
      }
    }
  }

  .my-bottom {
    position: absolute;
    width: 100%;
    bottom: 10px;
    padding: 0 16px;
    height: 245px;
    display: flex;
    justify-content: space-between;
  }

  .my-echar {
    width: 420px;
    height: 245px;
    left: 16px;
    background: url(https://static.nagaiot.cn/web/bigScreen/title_3.png) no-repeat;
    background-size: 100%;
  }

  .my-echar-title,
  .left-t-title {
    height: 40px;
    line-height: 40px;
    text-align: left;
    padding-left: 24px;
    color: #ddebf4;
    font-size: 16px;
  }

  #power-consumption,
  #flow,
  #ph,
  #turbidity {
    height: 186px;
  }
}
</style>
<style lang="less">
.MonitoringPanel {}
</style>
