import { Storage } from "@/customizeObj/storage";
const storage = new Storage();

import {
    CATEGORYMODELS,
    ORGANID,
    ISREADONLY,
    CATEGORYID
} from '../mutation-type';

const state = {
    categoryModels: [], // 类别型号
    organid:'',//单位id
    isReadonly:false,
    categoryId:'',
    ...(storage.localGet('iot.state') ? storage.localGet('iot.state').project : {}),
};

const mutations = {
    [CATEGORYMODELS]: (s: any, categoryModels: any[]) => {
        s.categoryModels = categoryModels;
    },
    [ORGANID]: (s: any, organid:string) => {
        s.organid = organid;
    },
    [ISREADONLY]: (s: any, isReadonly:boolean) => {
        s.isReadonly = isReadonly;
    },
    [CATEGORYID]: (s: any, categoryId:string) => {
        s.categoryId = categoryId;
    },
}

export default {
    state,
    mutations,
};
