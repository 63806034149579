export class Storage extends Object{
    constructor() {
        super()
    }
    // 存储,可设置过期时间
    localSet(key: string, value: any, expires = 0) {
        const params = { key, value, expires };
        let cpVal = '';
        if (expires) {
            // 记录何时将值存入缓存，毫秒级
            const data = Object.assign(params, { startTime: new Date().getTime() });
            localStorage.setItem(key, JSON.stringify(data));
        } else {
            if (Object.prototype.toString.call(value) === '[object Object]') {
                cpVal = JSON.stringify(value);
            }
            if (Array.isArray(value)) {
                cpVal = JSON.stringify(value);
            }
            localStorage.setItem(key, cpVal);
        }
    }
    // 取出
    localGet(key: string) {
        const item = localStorage.getItem(key);
        if (!item) {
            return item;
        }
        let cpItem = null;
        // 先将拿到的试着进行json转为对象的形式
        try {
            cpItem = JSON.parse(item);
        } catch (error) {
            cpItem = item;
        }
        // 如果有startTime的值，说明设置了失效时间
        if (cpItem && cpItem.startTime) {
            const date = new Date().getTime();
            // 如果大于就是过期了，如果小于或等于就还没过期
            if (date - cpItem.startTime > cpItem.expires) {
                localStorage.removeItem(key);
                return false;
            }
        }
        return cpItem;
    }

    // 只出去value
    localGetVal(key: string) {
        const item = localStorage.getItem(key);
        if (!item) {
            return item;
        }
        let cpItem = null;
        // 先将拿到的试着进行json转为对象的形式
        try {
            cpItem = JSON.parse(item);
        } catch (error) {
            cpItem = item;
        }
        // 如果有startTime的值，说明设置了失效时间
        if (cpItem && cpItem.startTime) {
            const date = new Date().getTime();
            // 如果大于就是过期了，如果小于或等于就还没过期
            if (date - cpItem.startTime > cpItem.expires) {
                localStorage.removeItem(key);
                return false;
            }
        }
        return JSON.parse(cpItem.value);
    }
    // 删除
    localRemove(key: string) {
        localStorage.removeItem(key);
    }
    // 清除全部
    localClear() {
        localStorage.clear();
    }

    sessionGet(key: string) {
        const data = sessionStorage.getItem(key);
        if (!data) {
            return null;
        }
        return JSON.parse(data).value;
    }

    sessionSet(key: string, value: any) {
        const data = {
            value,
        };
        sessionStorage[key] = JSON.stringify(data);
    }
    sessionRemove(key:string) {
        sessionStorage.removeItem(key);
    }
    // 清除全部
    sessionClear() {
        sessionStorage.clear();
    }
}