<template>
  <div class="MonitoringPanelList">
    <ul>
      <li
        class="cs"
        v-for="item in dataList"
        :key="item.id"
        @click="addPane(item.id)"
      >
        <img :src="item.backGroundImgUrl" alt="" />
        <div class="title">{{ item.title }}</div>
      </li>
      <li class="add cs" @click="addPane()">
        <img
          src="https://static.nagaiot.cn/web/icon_add.png"
          alt=""
        />
        <div class="add_title">添加自定义大屏</div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import apiServer from "@/api/request";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "MonitoringPanelList",
  setup() {
    const router = useRouter();
    let dataList = reactive([] as any[]);
    LargeScreenPanelGetList();
    function LargeScreenPanelGetList() {
      let orderId = router.currentRoute.value.query.orderId;
      let params = {
        orderId,
      };
      apiServer.LargeScreenPanelGetList(params).then((res: any) => {
        console.log(res);
        if (res.status == 1) {
          dataList.push(...res.data);
        }
      });
    }

    //进入详情
    function addPane(id = "") {
      if (id) {
        router.push(
          "/monitoringPanel?orderId=" + router.currentRoute.value.query.orderId+'&id='+id
        );
      } else {
        router.push(
          "/monitoringPanel?orderId=" + router.currentRoute.value.query.orderId
        );
      }
    }
    return {
      dataList,
      addPane,
    };
  },
});
</script>

<style scoped lang="less">
.MonitoringPanelList {
  background: #020a19;
  min-height: 100vh;
  color: white;
  ul {
    width: 1660px;
    margin: 0 auto;
    padding-top: 60px;
    overflow: hidden;
    li {
      width: 370px;
      height: 254px;
      float: left;
      margin-right: 60px;
      border-radius: 10px;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: 210px;
      }
      .title {
        height: 44px;
        line-height: 44px;
        text-align: center;
        color: white;
        background: url("https://static.nagaiot.cn/web/bg.png")
          no-repeat;
        font-size: 18px;
        font-weight: 500;
      }
    }
    .add {
      padding-top: 61px;
      background: #0e1a37;
      img {
        display: block;
        width: 72px;
        height: 72px;
        margin: 0 auto;
      }
      .add_title {
        margin-top: 30px;
        font-size: 20px;
        color: #2074ff;
      }
    }
  }
}
</style>
