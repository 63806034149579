<template>
  <div class="DataAndManagement DataAndManagement-nopx" v-if="emptyPage">
    <div class="module_list">
      <div class="module_item fs16 cs" :class="categoryId == item.categoryId ? 'active' : ''" v-for="item in modules"
        :key="item" @click="changeActive(item)">
        <i class="iconfont" :class="item.icon"></i> {{ item.categoryName }}
      </div>
    </div>
    <router-view />
  
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, inject, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "DataAndManagement",
  components: {
  },
  setup() {
    const emitt: any = inject("emitt");

    const emptyPage = ref(false); //页面是否为空
    const modules = ref([] as any[]); //分类列表
    const categoryId = ref(""); //分类Id
    const orderId = ref(""); //id
    const router = useRouter();

    if (localStorage.GetCategory) {
      const GetCategory = JSON.parse(localStorage.GetCategory);
      modules.value = GetCategory.categoryModels;
      categoryId.value = GetCategory.categoryModels[0].categoryId;
      orderId.value = JSON.parse(localStorage.GetCategory).orderId;
      if (modules.value.length != 0) {
        emptyPage.value = true;
      }
    }

    emitt.$sub("firstValue", (val: any) => {
      console.log('firstValuefirstValuefirstValuefirstValue',val[0])
      let v = val[0];
      const GetCategory = v;
      modules.value = GetCategory.categoryModels;
      categoryId.value = GetCategory.categoryModels[0].categoryId;
      orderId.value = GetCategory.orderId;
      if (modules.value.length != 0) {
        emptyPage.value = true;
      }
      localStorage.categoryModels = JSON.stringify(GetCategory.categoryModels[0]);
    });



    function changeActive(item: any) {
      categoryId.value = item.categoryId;
      localStorage.categoryModels = JSON.stringify(item);
      router.push('/')
      emitt.$pub("categoryIdChange");
    }

    // //管理弹窗
    // function openDialog(e: string) {
    //   switch (e) {
    //     case "RealTimeData":
    //       emitt.$pub("openRealTimeData", deviceList.value);
    //       break;
    //     case "StatisticsSettings":
    //       // emitt.$pub("openStatisticsSettings", {
    //       //   basicStatisticDataList,
    //       //   displayBasicStatisticData,
    //       // });
    //       break;
    //     case "ControlItem":
    //       // emitt.$pub("openControlItem", controllableList.value);
    //       break;
    //   }
    // }

    onBeforeUnmount(() => {
      emitt.$unsub("firstValue");
    });
    return {
      emptyPage,
      categoryId,
      modules,
      changeActive,
      orderId,
    };
  },
});
</script>

<style scoped lang="less">
.DataAndManagement-nopx {
  overflow: hidden;
  height: calc(100vh - 60px);
  .module_list {
    width: 240px;
    height: 100%;
    background: #191a22;
    color: #ffffff;
    float: left;

    .module_item {
      height: 48px;
      line-height: 48px;
      text-align: left;
      padding-left: 24px;
    }

    .active {
      background: #1f62d2;
    }
  }
}
</style>
