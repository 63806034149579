<template>
    <div class="FooterComponent FooterComponent-nopx">
        @2023 湖南鑫泰云有限公司
    </div>
  </template>
  
  <script lang="ts">
  import {
    defineComponent,
  } from "vue";
  
  export default defineComponent({
    name: "FooterComponent",
    setup() {
      
      return {
       
      };
    },
  });
  </script>
  
  <style scoped lang="less">
  .FooterComponent-nopx {
    font-size: 14px;
    color: #8D8F97;
    margin: 40px 0;
  }
  </style>
  