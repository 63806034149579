<template>
  <div class="HeaderNav HeaderNav-nopx">
    <div v-if="emptyFlag">
      <nav>
        <div class="head_left">
          <div class="head_logo">
            <img src="https://static.nagaiot.cn/web/logo_sdssddfgi.png" alt="" />
          </div>
          <div class="project_list" :style="{ width: productName.length * 16 + 80 + 'px' }">
            <el-select v-model="orderId" class="m-2" placeholder="请选择" @change="changeOrder">
              <div v-for="item in projectList" :key="item.orderId">
                <el-option :label="'（主）' + item.productName" :value="item.orderId" />
                <el-option v-for="i in item.mergeProducts" :key="i.orderId" :label="'（从）' + i.productName"
                  :value="i.orderId" />
              </div>
              <template #footer>
                <div class="tc">
                  <el-button size="small" @click="dialogVisible = true">
                    添加新项目
                  </el-button>
                </div>
              </template>
            </el-select>
          </div>
        </div>
        <div class="nav_box">
          <router-link to="/homeView"><i class="iconfont icon-icon_topbar_zl"></i> 总览</router-link>
          <router-link to="/"><i class="iconfont icon-icon_topbar_sjgl"></i> 数据&管理</router-link>
          <router-link to="/basicParameters"><i class="iconfont icon-icon_topbar_sjgl"></i> 设备基础参数</router-link>
          <router-link target="_blank" :to="'/monitoringPanel?orderId=' + orderId"><i
              class="iconfont icon-icon_topbar_jkmb"></i>
            监控面板</router-link>
          <a :href="'./IndustrialControl.html' +
            '?orderId=' +
            orderId
            " target="_blank"><i class="iconfont icon-icon_topbar_gkxt"></i> 工控系统</a>
        </div>
        <div class="lang" :style="{ width: lang.length * 16 + 80 + 'px' }">
          <el-select v-model="lang" class="m-2" placeholder="Select">
            <el-option label="简体中文" value="cn" />
            <el-option label="英文" value="en" />
          </el-select>
        </div>
        <div class="user cs" @click="gotoAccountCenter">
          <img :src="headImgUrl" alt="" />
          <div>{{ nickname }}</div>
        </div>
        <div class="monitor cs" @click="openAlarmModal">
          <div class="icon">
            <img src="https://static.nagaiot.cn/web/icon_alarm.png" alt="" />
            <div class="count" v-if="Number(warnCount) > 0">
              {{ warnCount }}
            </div>
          </div>
          <div>监控报警</div>
        </div>
        <div class="signOut cs" @click="signOut">安全退出</div>
      </nav>
    </div>
    <div class="" v-else>
      <nav></nav>
    </div>
    <AlarmModal :orderId="orderId" />
    <el-dialog v-model="dialogVisible" :close-on-click-modal="false" title="添加新项目" width="380px" :show-close="false">
      <template #header>
        <div class="dialog-title" v-if="step == 1 || step == 2 || step == 3">
          添加新项目
        </div>
        <div class="dialog-title" v-if="step == 4">选择主项目</div>
        <div class="dialog-subTitle" v-if="step == 4">
          从项目关联主项目设备后才可运行
        </div>
        <div class="dialog-title" v-if="step == 5">请先成为主机管理员</div>
        <div class="dialog-title" v-if="step == 6">请联系管理员</div>
      </template>
      <div v-if="step == 1">
        <el-input v-model="productStoreCode" size="large" placeholder="请输入项目编号" />
      </div>
      <div v-if="step == 2 || step == 3">
        <el-input v-model="organName" size="large" placeholder="请输入公司全称" :disabled="disabled" />
        <el-input v-model="orderName" size="large" class="mt24" placeholder="请输入项目名称" />
      </div>
      <div v-if="step == 4">
        <el-radio-group v-model="addOrderId">
          <div v-for="item in hostProducts" :key="item.orderid">
            <el-radio :label="item.orderid"> {{ item.productname }}</el-radio>
          </div>
        </el-radio-group>
      </div>
      <div v-if="step == 5">
        <p>
          您扫描的设备为“从项目”设备，不可单独使用，需要您先成为“主项目”的管理员才可将添加该“从项目”。
        </p>
      </div>
      <div v-if="step == 6">
        <p>您扫描的设备已有管理员在管理，请联系管理员将您添加至该项目。</p>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="btn" size="large" @click="handleClose">取消</el-button>
          <el-button class="btn" size="large" type="primary" @click="confirm" :disabled="!disabled1">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog width="300" class="warn-dialog" v-model="warnDialogVisible" :show-close="false"
      :close-on-click-modal="false">
      <div>
        <img src="https://static.nagaiot.cn/web/bigScreen/pic_alarm.png" alt="" />
        <p class="warn-title">{{ warnContent.title }}</p>
        <p class="warn-productName">{{ warnContent.productName }}</p>
        <p class="warn-text">{{ warnContent.WarnValue }}</p>
        <p class="warn-text">{{ warnContent.TriggerWarnValue }}</p>
        <p class="warn-text">{{ warnContent.createTime }}</p>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="btn" size="large" @click="seeNow(1)">取消</el-button>
          <el-button class="btn" size="large" type="primary" @click="seeNow(2)">
            立即查看
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  inject,
  onBeforeUnmount,
  computed,
  reactive,
} from "vue";
import apiServer from "@/api/request";
import AlarmModal from "@/components/AlarmModal.vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "HeaderNav",
  components: {
    AlarmModal,
  },
  setup() {
    const emitt: any = inject("emitt");
    const router = useRouter();
    const route = useRoute();
    const projectList = ref([] as any[]);
    const orderId = ref("");
    const productName = ref("");
    const emptyFlag = ref(true);
    const warnCount = ref("");
    const lang = ref("cn"); //语言
    const dialogVisible = ref(false);
    const productStoreCode = ref("");
    const step = ref(1);
    const organName = ref("");
    const disabled = ref(false);
    const orderName = ref("");
    const checkResult = ref(null);
    const addOrderId = ref("");
    const hostProducts = ref([] as any[]);
    const warnDialogVisible = ref(false); //警告弹窗
    let unreadWarnList = [] as any[]; //未读报警
    const warnContent = reactive({
      //提示框内容
      id: "",
      title: "",
      productName: "",
      WarnValue: "",
      TriggerWarnValue: "",
      createTime: "",
      orderId: ''
    });
    const nickname = ref('');
    const headImgUrl = ref('');

    function handleClose() {
      step.value = 1;
      productStoreCode.value = "";
      dialogVisible.value = false;
    }

    GetCategory();
    GetUserInfo();
    //按钮提交条件-开始
    const disabled1 = computed(() => {
      return productStoreCode.value != "";
    });

    //获取用户信息
    function GetUserInfo() {
      apiServer.GetUserInfo().then((res: any) => {
        if (res.status == 1) {
          console.log("GetUserInfo", res)
          headImgUrl.value = res.data.headurl
          nickname.value = res.data.nickname
        }
      })
    }


    //获取项目
    function GetCategory() {
      apiServer.GetCategory().then((res: any) => {
        if (res.status == 1) {
          if (res.data.list.length == 0) {
            emptyFlag.value = false;
            router.push("/emptyPage");
            return;
          }

          if (route.path == "/emptyPage") {
            router.push("/");
          }

          emptyFlag.value = true;
          projectList.value = res.data.list;

          if (localStorage.GetCategory) {
            orderId.value = JSON.parse(localStorage.GetCategory).orderId;
            productName.value = JSON.parse(
              localStorage.GetCategory
            ).productName;
          } else {
            localStorage.GetCategory = JSON.stringify(projectList.value[0]);
            orderId.value = res.data.list[0].orderId;
            productName.value = res.data.list[0].productName;
            console.log("projectList.value[0]", projectList.value[0])
            emitt.$pub("firstValue", projectList.value[0]);
          }
          GetUnReadNum();
        }
      });
    }

    function changeOrder(e: any) {
      for (let i in projectList.value) {
        if (projectList.value[i].orderId == e) {
          localStorage.GetCategory = JSON.stringify(projectList.value[i]);
        }
        for (const item of projectList.value[i].mergeProducts) {
          if (item.orderId == e) {
            localStorage.GetCategory = JSON.stringify(item);
          }
        }
      }
      window.location.reload();
    }

    let timer: any;
    function myInterval() {
      timer = setInterval(() => {
        // GetUnReadNum();
        console.log("123");
      }, 5000);
    }
    //查询警告未读条数
    function GetUnReadNum() {
      let params = {

      };
      apiServer.GetUnReadNum(params).then((res: any) => {
        if (res.status == 1) {
          console.log("GetUnReadNum", res);
          if (Number(res.data.unReadNum) > 99) {
            warnCount.value = "99+";
          } else {
            warnCount.value = res.data.unReadNum;
          }
          if (res.data.list.length > 0) {
            unreadWarnList = res.data.list;
            unreadWarnListDialog(unreadWarnList);
          }
        }
      });
    }

    function unreadWarnListDialog(list: any) {
      if (list.length > 0) {
        warnContent.id = list[0].id;
        warnContent.orderId = list[0].orderId;
        warnContent.title = list[0].title;
        warnContent.productName = list[0].productName;
        warnContent.createTime = list[0].createTime;
        warnContent.WarnValue = list[0].pathObj.WarnValue;
        warnContent.TriggerWarnValue = list[0].pathObj.TriggerWarnValue;
        warnDialogVisible.value = true;
        unreadWarnList.splice(0, 1);
      } else {
        return;
      }
    }

    function openAlarmModal() {
      emitt.$pub("openAlarmModal");
    }
    emitt.$sub("refresh", () => {
      GetCategory();
    });
    emitt.$sub("updateUserInfo", () => {
      GetUserInfo();
    });
    onBeforeUnmount(() => {
      emitt.$unsub("refresh");
      emitt.$unsub("updateUserInfo");
      clearInterval(timer);
    });

    //安全退出
    function signOut() {
      localStorage.clear();
      router.push("/loginPage");
    }

    //添加项目
    function CheckOrder() {
      let params = {
        productStoreCode: productStoreCode.value,
      };
      apiServer.CheckOrder(params).then((res: any) => {
        if (res.status == 1) {
          checkResult.value = res.data.checkResult;
          switch (Number(checkResult.value)) {
            case 1:
              step.value = 2;
              break;
            case 2:
              step.value = 3;
              disabled.value = true;
              organName.value = res.data.oragnName;
              break;
            case 3:
              hostProducts.value = res.data.hostProducts;
              step.value = 4;
              break;
            case 4:
              step.value = 5;
              break;
            case 5:
              step.value = 6;
              break;
          }
          console.log(res);
        } else {
          ElMessage.error(res.message);
        }
      });
    }

    function AddOrder() {
      let params = {
        productStoreCode: productStoreCode.value,
        checkResult: checkResult.value,
        orderId: addOrderId.value,
        organName: organName.value,
        orderName: orderName.value,
      };
      let data = {};
      apiServer.AddOrder(params, data).then((res: any) => {
        if (res.status == 1) {
          ElMessage.success(res.message);
          window.location.reload();
        } else {
          ElMessage.error(res.message);
        }
      });
    }

    function confirm() {
      console.log(step.value);
      if (step.value == 1) {
        CheckOrder();
      } else if (step.value == 2 || step.value == 3 || step.value == 4) {
        AddOrder();
      }
    }

    //立即查看
    function seeNow(type: number) {
      let data = {
        orderId: orderId.value,
        startId: warnContent.id,
        endId: warnContent.id,
      };
      let params = {};
      apiServer.SetAsRead(params, data).then((res: any) => {
        if (res.status == 1) {
          if (type == 1) {
            unreadWarnListDialog(unreadWarnList);
          } else {
            if (orderId.value != warnContent.orderId) {
              changeOrder(warnContent.orderId);
            } else {
              warnDialogVisible.value = false;
              router.push("/");
            }
          }
        }
      });
    }

    function gotoAccountCenter() {
      router.push("/accountCenter");
    }
    return {
      projectList,
      orderId,
      changeOrder,
      openAlarmModal,
      signOut,
      productName,
      emptyFlag,
      warnCount,
      lang,
      dialogVisible,
      productStoreCode,
      disabled1,
      organName,
      orderName,
      disabled,
      step,
      handleClose,
      confirm,
      hostProducts,
      addOrderId,
      warnDialogVisible,
      warnContent,
      seeNow,
      gotoAccountCenter,
      headImgUrl,
      nickname
    };
  },
});
</script>

<style scoped lang="less">
.HeaderNav-nopx {

  nav {
    height: 60px;
    background: #2074ff;
    position: relative;

    .nav_box {
      width: 700px;
      height: 60px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-left: 20.8333%;

      a {
        padding: 0 24px;
      }
    }

    .lang {
      position: absolute;
      right: 376px;
      top: 14px;
      font-size: 14px;
    }

    .user {
      position: absolute;
      right: 124px;
      top: 16px;
      font-size: 14px;
      color: white;
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 24px;
        height: 24px;
        margin-right: 8px;
        border-radius: 50%;
      }

      div {
        width: 80px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
      }
    }

    a {
      color: white;
      text-decoration: none;

      &.router-link-exact-active {
        font-weight: 600;
      }
    }

    .monitor {
      display: flex;
      justify-content: left;
      align-items: center;
      color: white;
      position: absolute;
      right: 264px;
      top: 18px;

      .icon {
        width: 18px;
        height: 18px;
        margin-right: 12px;
        border-radius: 50%;
        position: relative;

        img {
          width: 18px;
          height: 18px;
        }

        .count {
          line-height: 10px;
          background: #ff2626;
          border: 1px solid white;
          border-radius: 14px;
          font-size: 10px;
          position: absolute;
          left: 10px;
          top: -7px;
          padding: 2px 3px;
        }
      }
    }

    .signOut {
      position: absolute;
      right: 24px;
      top: 14px;
      width: 80px;
      height: 32px;
      background: white;
      color: #2074ff;
      text-align: center;
      line-height: 32px;
      border-radius: 16px;
      font-size: 14px;
    }

    .head_left {
      position: absolute;
      left: 20px;
      top: 14px;
      display: flex;
      justify-content: center;
      height: 32px;
      align-items: center;
    }

    .head_logo {
      height: 24px;
      padding-right: 20px;
      border-right: 1px solid #82b1ff;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .project_list {
      margin-left: 10px;
    }
  }
}
</style>
<style lang="less">
.HeaderNav-nopx {

  .project_list,
  .lang {
    text-align: left;

    .el-select:hover:not(.el-select--disabled) .el-input__wrapper {
      box-shadow: none;
    }

    .el-input__wrapper {
      .el-input__inner {
        font-weight: 600;
        font-size: 14px;
      }

      background: none;
      box-shadow: none;

      .el-input__inner,
      .el-icon {
        color: white;
      }
    }
  }

  .dialog-footer {
    .btn {
      width: 158px;
      font-size: 16px;
    }
  }

  .mt24 {
    margin-top: 24px;
  }

  .dialog-title {
    font-size: 16px;
    font-weight: 500;
  }

  .dialog-subTitle {
    font-size: 12px;
    margin-top: 4px;
  }

  .el-radio-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .warn-dialog {
    .el-dialog__header {
      padding: 0 !important;
    }

    .el-dialog__body {
      padding: 16px 24px 24px 24px;
    }

    img {
      width: 120px;
      height: 90px;
    }

    .warn-title {
      color: #ff0000;
      font-size: 16px;
      font-weight: 500;
    }

    .warn-productName {
      font-size: 16px;
      font-weight: 500;
      margin-top: 24px;
    }

    .warn-text {
      text-align: left;
      margin-top: 16px;
    }

    .btn {
      width: 118px;
    }
  }
}
</style>
