<template>
    <div class="logComponent logComponent-nopx">
        <div class="title">{{ deviceName }}</div>
        <div class="log_box">
            <div class="search">
                <span>时间</span>
                <el-date-picker v-model="monitorTime" format="YYYY-MM-DD" value-format="YYYY-MM-DD" type="date" />
                <el-button type="primary" class="search_btn" @click="Search">
                    <i class="iconfont icon-icon_btn_search mr4"></i> 查询
                </el-button>
            </div>
            <div class="line">
                <div class="line-item" v-for="item in dataList" :key="item.consumableName">
                    <span class="point"></span>
                    <span class="time">{{ item.time }}</span>
                    <span>{{ item.operateContent }} </span>
                </div>
                <div class="empty" v-if="emptyFlag">
                    <img src="https://static.nagaiot.cn/web/bigScreen/icon_nodata_white.png" alt="">
                    <p>暂无数据</p>
                </div>
            </div>
        </div>
        <FooterComponent />
    </div>
</template>
<script lang="ts">
import { defineComponent, ref, reactive, inject } from "vue";
import apiServer from "@/api/request";
import { ElMessage, ElMessageBox } from "element-plus";
import FooterComponent from "@/components/FooterComponent.vue";

export default defineComponent({
    name: "logComponent",
    props: {
        deviceName: {},
        deviceId: {},
    },
    components: {
        FooterComponent
    },
    setup() {
        const orderId = ref(JSON.parse(localStorage.GetCategory).orderId);
        const monitorTime = ref(formatDate());
        const dataList = reactive([] as any[]);
        const emptyFlag = ref(false);

        GetDeviceMonitorLog();

        function GetDeviceMonitorLog() {
            const params = {
                monitorTime: monitorTime.value,
                orderId: orderId.value
            }
            apiServer.GetDeviceMonitorLog(params).then((res: any) => {
                if (res.status == 1) {
                    if (res.data.list.length == 0) {
                        emptyFlag.value = true;
                    } else {
                        dataList.length = 0;
                        dataList.push(...res.data.list);
                        emptyFlag.value = false;
                    }
                }
            })
        }


        function Search() {
            GetDeviceMonitorLog();
        }

        function formatDate() {
            const today = new Date();
            const year = today.getFullYear().toString(); // 获取年份并转为字符串类型
            const month = (today.getMonth() + 1).toString().padStart(2, '0'); // 获取月份（注意月份从0开始计数）并补零到两位
            const day = today.getDate().toString().padStart(2, '0'); // 获取日期并补零到两位

            // 格式化为yyyy-mm-dd格式
            const formattedDate = `${year}-${month}-${day}`;
            console.log("formattedDate", formattedDate)
            return formattedDate;
        }

        return {
            monitorTime,
            Search,
            dataList,
            emptyFlag
        };
    },
});
</script>
  
<style lang="less" scoped>
.logComponent-nopx {
    height: calc(100vh - 123px);
    overflow-y: scroll;

    .title {
        font-size: 20px;
        font-weight: 500;
        text-align: left;
        padding: 0 24px;

    }

    .log_box {
        padding: 24px;
        margin: 20px 24px;
        background: white;
        border-radius: 8px;
        min-height: 730px;

        .search {
            display: flex;
            justify-content: left;
            align-items: center;

            span {
                margin-right: 24px;
                font-weight: 500;
            }

            .search_btn {
                margin-left: 16px;
            }
        }

        .line {
            margin-top: 40px;

            .line-item {
                display: flex;
                justify-content: left;
                font-size: 14px;
                align-items: center;
                margin-top: 30px;
                position: relative;

                &::after {
                    content: "";
                    display: block;
                    width: 1px;
                    height: 47px;
                    background: #2074FF;
                    position: absolute;
                    top: 9px;
                    left: 4.2px;
                }

                &:last-child {
                    &::after {
                        display: none;
                    }
                }
            }

            .point {
                width: 9px;
                height: 9px;
                background: #2074FF;
                border-radius: 50%;
            }

            .time {
                margin-left: 20px;
                margin-right: 16px;
            }

            .empty {
                width: 350px;

                p {
                    font-size: 12px;
                }
            }
        }
    }

}
</style>
<style lang="less">
.logComponent-nopx {}
</style>
  