<template>
    <div class="AccountCenter-nopx">
        <div class="center">
            <ul>
                <li class="active"><i class="iconfont icon-icon_leftbar_zh_black"></i>账户信息</li>
                <router-link to="/systemInformation">
                    <li><i class="iconfont icon-icon_leftbar_xt_black"></i>系统消息</li>
                </router-link>
                <router-link to="/weeklyPage">
                    <li><i class="iconfont icon-icon_leftbar_zb_black"></i>周报</li>
                </router-link>
            </ul>
            <div class="content">
                <div class="line">
                    <div class="lable pt30">头像</div>
                    <div class="value">
                        <img class="head-img" :src="headImgUrl">
                        <div class="change-img cs" @click="OpenHeadImg">
                            <i class="iconfont icon-icon_leftbar_xt_black"></i>修改头像
                        </div>
                    </div>
                </div>
                <div class="line">
                    <div class="lable">昵称</div>
                    <div class="value">
                        <el-input v-model="nickname" placeholder="未设置昵称" size="large" readonly></el-input>
                    </div>
                    <div class="btn_op cs" @click="OpenNickname">
                        <i class="iconfont icon-icon_btn_xg"></i> 修改
                    </div>
                </div>
                <div class="line">
                    <div class="lable">手机号</div>
                    <div class="value">
                        <el-input v-model="phone" placeholder="请输入手机号" size="large" readonly></el-input>
                    </div>
                </div>
                <div class="line">
                    <div class="lable">账号密码</div>
                    <div class="value" v-if="password">
                        <el-input v-model="password" placeholder="未设置账号密码" size="large"></el-input>
                    </div>
                    <div class="btn_op cs" @click="OpenPassword">
                        <i class="iconfont"
                            :class="password.length == 0 ? 'icon-icon_btn_xg' : 'icon-icon_btn_set_blue'"></i> {{
                                password.length
                                == 0 ? '修改' : '设置' }}
                    </div>
                </div>
            </div>
        </div>
        <el-dialog v-model="dialogVisibleHeadImg" title="修改头像" width="300" :show-close="false">
            <div class="head-img-box">
                <img class="head-img" :src="headImgUrl" id="myImg" alt="">
                <div class="example-headImg" @click="chooseCaptionPhoto">
                    <input type="file" name="headImg" accept="image/*" class="upload-headImg" ref="headImg">
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button class="btn" size="large" @click="CancelHeadImg">取消</el-button>
                    <el-button class="btn" size="large" type="primary" @click="ConfirmHeadImg">
                        确定
                    </el-button>
                </span>
            </template>
        </el-dialog>
        <el-dialog v-model="dialogVisibleNickname" title="修改昵称" width="300" :show-close="false">
            <el-input v-model="nicknameNew" maxlength="10" placeholder="请输入昵称" />
            <template #footer>
                <span class="dialog-footer">
                    <el-button class="btn" size="large" @click="CancelNickname">取消</el-button>
                    <el-button class="btn" size="large" type="primary" @click="ConfirmNickname">
                        确定
                    </el-button>
                </span>
            </template>
        </el-dialog>
        <el-dialog v-model="dialogVisiblePassword" title="设置账号密码" width="360" :show-close="false">
            <div class="tr">
                <el-input v-model="phone" size="large" readonly></el-input>
            </div>
            <div class="tr code_box">
                <el-input v-model="code" placeholder="请输入短信验证码" size="large">
                    <template #append><span class="cs" @click="GetByMobile">{{ sendMessage }}</span></template>
                </el-input>
            </div>
            <div class="tr code_box">
                <el-input v-model="passwordNew" maxlength="15" :type="eyesFlag ? 'text' : 'password'"
                    placeholder="请输入8-15位数字和字母组合的密码" size="large">
                    <template #append>
                        <i class="iconfont cs" :class="eyesFlag ? 'icon-icon_eye_yes_light' : 'icon-icon_eye_no_dack'"
                            @click="eyesFlag = !eyesFlag"></i>
                    </template>
                </el-input>
            </div>
            <div class="tr code_box">
                <el-input v-model="passwordNew2" maxlength="15" :type="eyesFlag2 ? 'text' : 'password'" placeholder="请确认密码"
                    size="large">
                    <template #append>
                        <i class="iconfont cs" :class="eyesFlag2 ? 'icon-icon_eye_yes_light' : 'icon-icon_eye_no_dack'"
                            @click="eyesFlag2 = !eyesFlag2"></i>
                    </template>
                </el-input>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button class="btn" size="large" @click="CancelPassword">取消</el-button>
                    <el-button class="btn" size="large" type="primary" @click="ConfirmPassword">
                        确定
                    </el-button>
                </span>
            </template>
        </el-dialog>
        <FooterComponent />
    </div>
</template>
<script lang="ts">
import { defineComponent, ref, inject } from "vue";
import compressImg from '@/utils/compressImage';
import apiServer from "@/api/request";
import { ElMessage } from "element-plus";
import FooterComponent from "@/components/FooterComponent.vue";

export default defineComponent({
    name: "AccountCenter",
    components: {
        FooterComponent
    },
    setup() {
        const emitt: any = inject("emitt");


        const nickname = ref('');
        const nicknameNew = ref('');
        const phone = ref('');
        const password = ref('');
        const dialogVisibleHeadImg = ref(false);
        const dialogVisibleNickname = ref(false);
        const dialogVisiblePassword = ref(false);
        const headImgUrl = ref("");
        const submitFlag = ref(false);
        const code = ref('');
        const passwordNew = ref('');
        const passwordNew2 = ref('');
        const eyesFlag = ref(false);
        const eyesFlag2 = ref(false);
        const sendMessage = ref("获取验证码");
        const activeName = ref('1');

        GetUserInfo();

        function OpenHeadImg() {
            window.document.getElementById('myImg')?.setAttribute('src', headImgUrl.value)
            dialogVisibleHeadImg.value = true;

        }
        function OpenNickname() {
            nicknameNew.value = nickname.value;
            dialogVisibleNickname.value = true;
        }

        function OpenPassword() {
            code.value = '';
            passwordNew.value = '';
            passwordNew2.value = '';
            code.value = '';
            dialogVisiblePassword.value = true;
        }

        function CancelNickname() {
            dialogVisibleNickname.value = false;
        }

        function CancelHeadImg() {
            dialogVisibleHeadImg.value = false;
        }
        function CancelPassword() {
            dialogVisiblePassword.value = false;
        }

        let headImgBase64 = '';

        function ConfirmHeadImg() {
            console.log('confirm', headImgBase64)
            const params = {
                headPic: headImgBase64
            }

            apiServer.SetHeadPic(params).then((res: any) => {
                if (res.status == 1) {
                    ElMessage.success('修改成功')
                    emitt.$pub("updateUserInfo");
                    GetUserInfo();
                    dialogVisibleHeadImg.value = false;
                }
            })
        }

        function ConfirmNickname() {
            const params = {
                nickName: nicknameNew.value
            }
            apiServer.SetNickName(params).then((res: any) => {
                if (res.status == 1) {
                    ElMessage.success('修改成功')
                    emitt.$pub("updateUserInfo");
                    CancelNickname();
                    GetUserInfo();
                }
            })
        }

        //确认修改密码
        function ConfirmPassword() {
            if (code.value == "") {
                ElMessage.error("请输入验证码");
                return;
            }
            if (passwordNew.value != passwordNew2.value) {
                ElMessage.error("两次密码不一样");
                return;
            }

            const regex = /^[a-zA-Z0-9]+$/;
            if (!regex.test(passwordNew.value)) {
                ElMessage.error("请输入8-15位数字和字母组合的密码");
                return;
            }

            if (passwordNew.value.length < 8) {
                ElMessage.error("请输入8-15位数字和字母组合的密码");
                return;
            }

            const params = {
                newPwd: window.btoa(passwordNew.value),
                account: window.btoa(phone.value),
                code: code.value,
                mobileAreaCode: "+86",
            }
            apiServer.ResetPassword(params).then((res: any) => {
                console.log("ResetPassword", res)
                if (res.status == 1) {
                    ElMessage.success('成功')
                    dialogVisiblePassword.value = false;
                } else {
                    ElMessage.error(res.message);
                }
            })
        }

        function chooseCaptionPhoto() {
            console.log('capture="camera"')
            const eleFile = document.querySelector('.upload-headImg');
            var preview = document.querySelector('.example-headImg');
            eleFile!.addEventListener('change', function () {
                var file = this.files[0];
                if (!file) return;
                // 确认选择的文件是图片
                if (file.type.indexOf("image") == 0) {
                    compressImg(file, 400).then((res: any) => {
                        submitFlag.value = true;
                        window.document.getElementById('myImg')?.setAttribute('src', res.compressBase64)
                        headImgBase64 = res.compressBase64

                    }).catch((res: any) => {
                        submitFlag.value = true;
                        document.getElementById('myImg')?.setAttribute('src', res.compressBase64)
                        headImgBase64 = res.compressBase64
                    })
                }
            });
        }

        //获取用户信息
        function GetUserInfo() {
            apiServer.GetUserInfo().then((res: any) => {
                if (res.status == 1) {
                    console.log("GetUserInfo", res)
                    headImgUrl.value = res.data.headurl
                    nickname.value = res.data.nickname
                    phone.value = res.data.phone
                    password.value = res.data.password
                }
            })
        }


        //发送验证码
        let timer: number | null = null;
        const time = ref(60);
        function GetByMobile() {
            if (phone.value == "") {
                ElMessage.error("请输入您的手机号码");
                return;
            }
            if (phone.value.length !== 11) {
                ElMessage.error("请输入正确的手机格式");
                return;
            }
            if (timer) return;
            try {
                var captcha = new window.TencentCaptcha("197132060", sendCodeFunc, {
                    userLanguage: "cn",
                    needFeedBack: false,
                });
                captcha.show();
            } catch (error) {
                loadErrorCallback(error);
            }

            function sendCodeFunc(result: any) {
                if (result.ret != 0) return;
                sendMessage.value = "正在发送";
                const params = {
                    smsType: 22,
                    phone: window.btoa(phone.value),
                    mobileAreaCode: "+86",
                };
                apiServer.GetByMobile(params).then((res: any) => {
                    if (res.status == 1) {
                        sendMessage.value = `${time.value}S`;
                        timer = setInterval(() => {
                            sendMessage.value = `${time.value}S`;
                            time.value -= 1;
                            if (time.value <= 0) {
                                clearInterval(timer as number);
                                sendMessage.value = "重新发送";
                                time.value = 60;
                                timer = null;
                            }
                        }, 1000);
                    } else {
                        ElMessage.error(res.message);
                        sendMessage.value = "获取验证码";
                    }
                });
            }
        }

        function loadErrorCallback(res: any) {
            var appid = "190327966";
            // 生成容灾票据或自行做其它处理
            var ticket =
                "terror_1001_" +
                appid +
                "_" +
                Math.floor(new Date().getTime() / 1000);
            callback({
                ret: 0,
                randstr: "@" + Math.random().toString(36).substr(2),
                ticket: ticket,
                errorCode: 1001,
                errorMessage: "jsload_error",
            });
        }
        function callback(res: any) {
            console.log("callback:", res);
            if (res.ret === 0) {
                console.log(res);
            }
        }

        return {
            nickname,
            phone,
            password,
            dialogVisibleHeadImg,
            headImgUrl,
            CancelHeadImg,
            ConfirmHeadImg,
            chooseCaptionPhoto,
            OpenHeadImg,
            OpenNickname,
            dialogVisibleNickname,
            CancelNickname,
            ConfirmNickname,
            nicknameNew,
            dialogVisiblePassword,
            code,
            passwordNew,
            passwordNew2,
            eyesFlag,
            eyesFlag2,
            GetByMobile,
            sendMessage,
            CancelPassword,
            ConfirmPassword,
            OpenPassword,
            activeName,
        };
    },
});
</script>
  
<style lang="less" scoped>
.AccountCenter-nopx {
    .center {
        width: 830px;
        margin: 0 auto;
        margin-top: 24px;
        display: flex;
        justify-content: center;

        ul {
            width: 90px;
            margin-right: 40px;

            li {
                margin-bottom: 28px;
                font-weight: 500;
                font-size: 14px;
                position: relative;
                padding-left: 10px;
                cursor: pointer;
                text-align: left;

                i {
                    margin-right: 5px;
                }

            }

            .active ::after {
                content: '';
                display: block;
                width: 4px;
                height: 18px;
                position: absolute;
                left: 0;
                top: 0;
                background: #2074FF;
            }
        }

        .content {
            width: 700px;
            background: white;
            border-radius: 8px;
            padding: 24px 0 80px 80px;

            .line {
                display: flex;
                justify-content: left;
                align-items: center;
                margin-top: 32px;

                .lable {
                    font-size: 14px;
                    width: 90px;
                    text-align: left;
                }

                .pt30 {
                    padding-top: 30px;

                }

                .value {
                    width: 360px;
                    text-align: left;
                    margin-right: 16px;

                    img {
                        width: 80px;
                        height: 80px;
                    }

                    .change-img {
                        padding: 6px 14px;
                        border: 1px solid #E7E7E7;
                        border-radius: 4px;
                        margin-top: 20px;
                        width: 110px;
                        font-size: 14px;

                        i {
                            margin-right: 8px;
                        }
                    }
                }

                .btn_op {
                    height: 40px;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    padding: 0 10px;
                    background: rgba(32, 116, 255, 0.1);
                    color: #2074FF;
                    border-radius: 4px;

                    i {
                        margin-right: 4px;
                    }
                }

                &:first-child {
                    margin-top: 0;
                    align-items: normal;
                }
            }
        }
    }

    .head-img-box {
        width: 80px;
        height: 80px;
        margin: 0 auto;
        position: relative;

        .example-headImg {
            width: 80px;
            height: 80px;
            position: absolute;
            top: 0;
            left: 0;

            input {
                width: 80px;
                height: 80px;
                cursor: pointer;
                opacity: 0;
            }

        }
    }

    .head-img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }

    .btn {
        width: 118px;
    }

    .tr {
        margin-bottom: 20px;
    }

    .code_box {
        span {
            color: #2074FF;
        }
    }

}
</style>
<style lang="less">
.AccountCenter-nopx {
    .el-dialog__header {
        border: 0;
    }

    .el-input-group__append {
        background: white;
    }

    .code_box {

        .el-input__wrapper {
            box-shadow: 0 1px 0 0 var(--el-input-border-color) inset, 0 -1px 0 0 var(--el-input-border-color) inset, 1px 0 0 0 var(--el-input-border-color) inset;
        }
    }
}</style>