<template>
  <div class="WarnComponent WarnComponent-nopx">
    <div class="title">{{ deviceName }}</div>
    <div class="content">
      <div class="content-head tl fw500">
        报警记录
      </div>
      <div class="content-body flex flex_jsb flex_wrap">
        <div class="content-item mb24" v-for="item in dataList" :key="item.id">
          <div class="fw500 fs14 tl text-ellipsis">{{ item.title }}</div>
          <div class="flex fs14 tl mt8">
            <div class="content-item-status">{{ item.currentValue }}</div>
            <div class="content-item-status">{{ item.warnValue }}</div>
            <div class="content-item-status">{{ item.createTime }}</div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination layout="prev, pager, next" small :pager-count="11" :total="total" :page-size="pageSize"
          @current-change="currentChange" />
      </div>
    </div>
    <FooterComponent />

  </div>
</template>
<script lang="ts">
import { defineComponent, ref, reactive, inject } from "vue";
import apiServer from "@/api/request";
import { ElMessage, ElMessageBox } from "element-plus";
import FooterComponent from "@/components/FooterComponent.vue";

export default defineComponent({
  name: "WarnComponent",
  props: {
    deviceName: {},
    deviceId: {},
  },
  components: {
    FooterComponent
  },
  setup(props) {

    const orderId = ref(JSON.parse(localStorage.GetCategory).orderId);
    const deviceId = ref(props.deviceId);
    const pageIndex = ref(1);
    const pageSize = ref(10);
    const total = ref(0);
    const dataList = reactive([] as any[]);


    GetMonitoringAlarmRecord();

    //查询报警记录
    function GetMonitoringAlarmRecord() {
      let params = {
        orderId: orderId.value,
        monitorDeviceId: deviceId.value,
        pageIndex: pageIndex.value,
        pageSize: pageSize.value,
      };
      apiServer.GetMonitoringAlarmRecord(params).then((res: any) => {
        console.log("GetMonitoringAlarmRecord", res);
        if (res.status == 1) {
          total.value = res.data.count;
          dataList.length = 0;
          Object.assign(dataList, res.data.list);
        } else {
          ElMessage.error(res.message)
        }
      });
    }

    function currentChange(val: number) {
      console.log("sizeChange", val);
      pageIndex.value = val;
      GetMonitoringAlarmRecord();
    }
    return {
      total,
      dataList,
      pageSize,
      currentChange
    };
  },
});
</script>

<style lang="less" scoped>
.WarnComponent-nopx {
  height: calc(100vh - 123px);
  overflow-y: scroll;
  .title {
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    padding: 0 24px;
  }

  .content {
    background: white;
    margin: 20px 16px;
    overflow: hidden;
    border-radius: 8px;

    .content-head {
      height: 52px;
      line-height: 52px;
      padding: 0 16px;
      border-bottom: 1px solid #E7E7E7;
    }

    .content-body {
      padding: 24px;

      .content-item {
        width: 47.7941%;
        border: 1px solid #E7E7E7;
        background: linear-gradient(180deg, #FDF1F1 0%, #FFFFFF 100%);
        border-radius: 4px;
        padding: 16px;
      }

      .content-item-status {
        width: 240px;
      }
    }

    .pagination {
      padding-right: 20px;
      display: flex;
      justify-content: right;
      margin-bottom: 10px;
    }
  }
}
</style>
<style lang="less">
.ConsumablesComponent {}
</style>
