import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-40b39b64"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "DataAndManagement DataAndManagement-nopx"
}
const _hoisted_2 = { class: "module_list" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.emptyPage)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modules, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["module_item fs16 cs", _ctx.categoryId == item.categoryId ? 'active' : '']),
              key: item,
              onClick: ($event: any) => (_ctx.changeActive(item))
            }, [
              _createElementVNode("i", {
                class: _normalizeClass(["iconfont", item.icon])
              }, null, 2),
              _createTextVNode(" " + _toDisplayString(item.categoryName), 1)
            ], 10, _hoisted_3))
          }), 128))
        ]),
        _createVNode(_component_router_view)
      ]))
    : _createCommentVNode("", true)
}