<template>
  <div class="AlarmModal">
    <el-dialog
      v-model="dialogVisible"
      title="监控报警"
      width="804"
      :show-close="false"
      close-on-click-modal
      :before-close="handleClose"
    >
      <div class="box">
        <el-tabs v-model="activeName" @tab-change="tabChange" class="demo-tabs">
          <el-tab-pane label="触发报警" name="1">
            <div class="alar_box">
              <div class="alar_list" v-for="item in dataList" :key="item.id">
                <div class="alar_list_left">
                  <div class="title">{{ item.title }}</div>
                  <div class="info">
                    <div class="currentValue">
                     {{ item.currentValue }}
                    </div>
                    <div class="warnValue">{{ item.warnValue }}</div>
                    <div class="createTime">{{ item.createTime }}</div>
                  </div>
                </div>
                <div class="alar_list_right" v-show="!item.isRead">
                  <el-button type="primary" @click="iknow(item)"
                    >知道了</el-button
                  >
                </div>
              </div>
            </div>
            <div class="pagination">
              <el-pagination
                layout="prev, pager, next"
                small
                :pager-count="11"
                :total="total"
                :page-size="pageSize"
                @current-change="currentChange"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane label="设置" name="2">
            <div class="alaset">
              <div
                class="alaset_item"
                v-for="(item, index) in alarmList"
                :key="item.id"
              >
                <div class="alaset_item_v1">
                  <img
                    class="cs"
                    src="https://static.nagaiot.cn/web/icon_del.png"
                    alt=""
                    @click="del(index)"
                  />
                </div>
                <div class="alaset_item_v2">
                  <div class="lable">报警名称</div>
                  <el-input
                    class="tl"
                    v-model="item.name"
                    placeholder="请输入报警名称"
                  ></el-input>
                </div>
                <div class="alaset_item_v3">
                  <div class="lable">触发条件</div>
                  <div class="demo-range">
                    <el-select
                      class="device"
                      v-model="item.targetDeviceId"
                      placeholder="请选择"
                      @change="triggerConditionDeviceIdChange(item)"
                    >
                      <el-option
                        v-for="e in controlList"
                        :key="e.deviceId"
                        :value="e.deviceId"
                        :label="e.deviceName"
                      ></el-option>
                    </el-select>
                    <el-select
                      class="operator"
                      v-model="item.operator"
                      placeholder="请选择"
                    >
                      <el-option :value="1" label="="></el-option>
                      <el-option
                        v-show="!item.disabled"
                        :value="2"
                        label=">"
                      ></el-option>
                      <el-option
                        v-show="!item.disabled"
                        :value="3"
                        label="≥"
                      ></el-option>
                      <el-option
                        v-show="!item.disabled"
                        :value="4"
                        label="<"
                      ></el-option>
                      <el-option
                        v-show="!item.disabled"
                        :value="5"
                        label="≤"
                      ></el-option>
                    </el-select>
                    <el-input
                      class="condition_result"
                      type="number"
                      v-model="item.targetValue"
                      placeholder="请输入"
                      v-show="!item.disabled"
                    ></el-input>
                    <el-select
                      v-model="item.targetValue"
                      placeholder="请选择"
                      v-show="item.disabled"
                    >
                      <el-option
                        :value="1"
                        :label="item.openStatusDesc"
                      ></el-option>
                      <el-option
                        :value="0"
                        :label="item.closeStatusDesc"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
                <div class="alaset_item_v4">
                  <div class="lable">频率</div>
                  <el-select v-model="item.frequency" placeholder="请选择">
                    <el-option :value="1" label="仅一次"></el-option>
                    <el-option :value="2" label="1分钟"></el-option>
                    <el-option :value="3" label="5分钟"></el-option>
                    <el-option :value="4" label="10分钟"></el-option>
                    <el-option :value="5" label="30分钟"></el-option>
                    <el-option :value="6" label="1小时"></el-option>
                  </el-select>
                </div>
                <div class="alaset_item_v5">
                  <el-switch v-model="item.status" />
                </div>
              </div>
            </div>
            <div class="dialog-footer">
              <el-button class="add" type="success" @click="add"
                >添加</el-button
              >
              <div class="fr">
                <el-button @click="handleClose">取消</el-button>
                <el-button type="primary" @click="save"> 保存 </el-button>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, inject, reactive, onBeforeUnmount } from "vue";
import apiServer from "@/api/request";
import { ElMessage } from "element-plus";
import { Control } from "@/utils/types";
import type { TabsPaneContext } from "element-plus";

export default defineComponent({
  name: "AlarmModal",
  props: {
    orderId: {
      type: String,
    },
  },
  setup(props) {
    const emitt: any = inject("emitt");
    const dialogVisible = ref(false);
    const activeName = ref("1");
    const pageIndex = ref(1);
    const pageSize = ref(5);
    const dataList = reactive([] as any[]);
    const alarmList = reactive([] as any[]);
    const total = ref(0);
    const controlList = reactive([] as any[]);
    const device = reactive({
      dataType: "",
      openStatusDesc: "",
      closeStatusDesc: "",
    });
    //注册-打开弹窗
    emitt.$sub("openAlarmModal", (val: any) => {
      dialogVisible.value = true;
      GetMonitoringAlarmRecord();
    });
    function handleClose() {
      dialogVisible.value = false;
      controlList.length = 0;
      alarmList.length = 0;
      activeName.value = "1";
    }
    function GetMonitoringAlarmRecord() {
      let params = {
        orderId: props.orderId,
        pageIndex: pageIndex.value,
        pageSize: pageSize.value,
      };
      apiServer.GetMonitoringAlarmRecord(params).then((res: any) => {
        console.log("GetMonitoringAlarmRecord", res);
        if (res.status == 1) {
          total.value = res.data.count;
          dataList.length = 0;
          Object.assign(dataList, res.data.list);
        }else{
          ElMessage.error(res.message)
        }
      });
    }

    //添加
    function add() {
      console.log("add");
      let obj = {
        id: 0, //触发Id,
        frequency: null, //周期
        targetDeviceId: null, //触发结果
        operator: null,
        name: null,
        status: true, //1-启用，2-禁用
        targetValue: null,
      };

      alarmList.push(obj);
    }

    //保存
    function save() {
      console.log("save");
      let items = [];
      for (let item of alarmList) {
        console.log("alarmList", alarmList);
        if (item.name == null || item.name == "") {
          ElMessage.error("请输入报警名称");
          return;
        }
        if (item.targetDeviceId == null || item.targetDeviceId == '') {
          ElMessage.error("请选择触发条件");
          return;
        }
        if (item.operator == null || item.operator == '') {
          ElMessage.error("请选择运算符");
          return;
        }
        if (item.targetValue == null || item.targetValue === '') {
          ElMessage.error("请输入触发值");
          return;
        }
        if (item.frequency == null || item.frequency == '') {
          ElMessage.error("请选择频率");
          return;
        }
        let obj = {
          id: 0, //触发Id,
          frequency: null, //周期
          targetDeviceId: null, //触发结果
          operator: null,
          name: null,
          status: 0, //1-启用，2-禁用
          targetValue:null
        };
        obj.id = item.id;
        obj.status = item.status ? 1 : 2;
        obj.frequency = item.frequency;
        obj.targetDeviceId = item.targetDeviceId;
        obj.operator = item.operator;
        obj.name = item.name;
        obj.targetValue = item.targetValue;
        items.push(obj);
      }
      let data = {
        orderId: props.orderId,
      };
      let params = {
        body: items,
      };
      apiServer.SetMonitoringAlarmData(params, data).then((res: any) => {
        if (res.status == 1) {
          ElMessage.success("保存成功");
          handleClose();
        }else{
          ElMessage.error(res.message);
        }
      });
    }

    //知道了
    function iknow(item: any) {
      let data = {
        orderId: props.orderId,
        startId: item.id,
        endId: item.id,
      };
      let params = {};
      apiServer.SetAsRead(params, data).then((res: any) => {
        if (res.status == 1) {
          item.isRead = true;
        }
      });
    }

    //修改分页
    function currentChange(val: number) {
      console.log("sizeChange", val);
      pageIndex.value = val;
      GetMonitoringAlarmRecord();
    }

    //删除监控报警
    function del(index: number) {
      alarmList.splice(index, 1);
    }

    //查询监控报警设置
    function GetMonitoringAlarmSetting() {
      let params = {
        orderId: props.orderId,
      };
      apiServer.GetMonitoringAlarmSetting(params).then((res: any) => {
        console.log("GetMonitoringAlarmSetting", res);
        for (let item of res.data.list) {
          let arr = controlList.filter(
            (e) => e.deviceId == item.targetDeviceId
          );
          if (arr.length > 0) {
            if (arr[0].dataType == "Bit") {
              item.disabled = true;
            } else {
              item.disabled = false;
            }
            item.openStatusDesc = arr[0].openStatusDesc;
            item.closeStatusDesc = arr[0].closeStatusDesc;
          }
          item.status = item.status == 1;
        }
        Object.assign(alarmList, res.data.list);
      });
    }

    //选择触发条件
    function triggerConditionDeviceIdChange(element: any) {
      let arr = controlList.filter((e: Control) => {
        return e.deviceControlId == element.targetDeviceId;
      });
      let item = arr[0];
      console.log(item);
      if (item.dataType == "Bit") {
        element.disabled = true;
        element.operator = 1;
        element.targetValue = 1;
        element.closeStatusDesc = item.closeStatusDesc;
        element.openStatusDesc = item.openStatusDesc;
      } else {
        element.disabled = false;
        element.targetValue = "";
      }
    }
    //查看监控设备列表 -- 所有的
    function GetMonitorDeviceList() {
      const params = {
        orderId: props.orderId,
      };
      apiServer.GetMonitorDeviceList(params).then((res: any) => {
        if (res.status == 1) {
          if (res.data.list.length != 0) {
            Object.assign(controlList, res.data.list);
            GetMonitoringAlarmSetting();
          }
        }
      });
    }

    function tabChange(tab: TabsPaneContext) {
      if (Number(tab) == 2 && controlList.length == 0) {
        GetMonitorDeviceList();
      }
    }
    onBeforeUnmount(() => {
      emitt.$unsub("openAlarmModal");
    });
    return {
      dialogVisible,
      save,
      activeName,
      pageIndex,
      pageSize,
      dataList,
      iknow,
      currentChange,
      total,
      del,
      alarmList,
      controlList,
      triggerConditionDeviceIdChange,
      device,
      tabChange,
      handleClose,
      add,
    };
  },
});
</script>

<style scoped lang="less">
.AlarmModal {
  .alar_box {
    padding: 16px 24px;
    .alar_list {
      width: 100%;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      background: #f6f7f8;
      align-items: center;
      margin-top: 12px;
      height: 80px;
      &:first-of-type {
        margin: 0;
      }
    }
    .title {
      text-align: left;
      font-size: 14px;
      font-weight: bold;
    }
    .info {
      width: 470PX;
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
    .alar_list_left{
    }
    .alar_list_right {
    }
  }
  .alaset {
    .alaset_item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 0 24px;
      margin-bottom: 16px;
      .lable {
        text-align: left;
        margin-bottom: 10px;
      }
    }
    .alaset_item_v1 {
      width: 20px;
    }
    .alaset_item_v2 {
      width: 200px;
    }
    .alaset_item_v3 {
      width: 300px;
      .demo-range {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .device,
        .condition_result {
        }
        .operator {
          margin: 0 10px;
        }
      }
      .to {
        margin: 0 10px;
      }
    }
    .alaset_item_v4 {
      width: 120px;
    }
  }
  .pagination {
    padding-right: 20px;
    display: flex;
    justify-content: right;
  }
  .dialog-footer {
    padding: 0 24px;
    overflow: hidden;
    height: 100%;
    width: 100%;
    .add {
      float: left;
    }
  }
}
</style>
<style lang="less">
.AlarmModal {
  .el-button {
    width: 120px;
  }
  .el-input__inner {
    text-align: left;
  }
  .el-tabs__nav {
    width: 100% !important;
  }
  .box {
    .el-tabs__item {
      width: 50% !important;
      padding: 0 !important;
    }
  }
  .el-dialog__body {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
  }
}
</style>
