<template>
  <div class="DeviceDetails-nopx">
    <div class="module_page">
      <div class="warn" v-if="warnFlag">
        <img src="https://static.nagaiot.cn/web/bigScreen/icon_alarm_red.png" alt="" />
        <span>{{ content }}</span>
        <span>{{ createTime }}</span>
      </div>

      <div class="control_panel" v-loading="loadingFlag1 || loadingFlag2">
        <div class="control_mode">
          <div class="control_mode_title">
            控制模式<img src="https://iot-1314976409.cos.ap-guangzhou.myqcloud.com/web/icon_sm.png" alt="">
          </div>
          <div class="mode">
            <div class="mode_item cs" :class="item.isChecked ? 'blue_w' : ''" v-for="item in modeList"
              :key="item.runModelName" @click="changeModel(item)">
              <i class="iconfont" :class="item.isChecked ? 'icon-icon_checkbox_yes' : 'icon-icon_checkbox_no'"></i> {{
                item.runModelName
              }}
            </div>
          </div>
        </div>
        <div class="control_panel_left">
          <div class="panel_box">
            <div class="panel_head">
              <div class="fw500">实时数据</div>
              <div class="pc cs" v-if="!isReadonly" @click="openRealTimeData()">
                管理
              </div>
            </div>
            <div class="panel_body">
              <div class="panel_item panel_item_fl" v-for="item in deviceList" :key="item.deviceId" v-show="item.isShow">
                <div class="panel_item_top fw600 fs18" :class="item.monitorValueResult == '开启'
                  ? 'open'
                  : item.monitorValueResult == '关闭'
                    ? 'close'
                    : ''
                  ">
                  {{ item.monitorValueResult }}
                </div>
                <div class="panel_item_bottom fs14">
                  {{ item.deviceControlName }}
                </div>
              </div>
              <div class="empty1" v-if="empty1">
                <img src="https://static.nagaiot.cn/web/bigScreen/icon_nodata_white.png" alt="" />
                <div>暂无数据</div>
              </div>
            </div>
          </div>
        </div>
        <div class="controlByIdList">
          <div class="control_item cs" v-for="item in controlByIdList" :key="item.deviceId"
            @click="gotoControlDetails(item)">
            <img :src="item.deviceIcon" alt="" />
            <div class="item_right">
              <div class="name">{{ item.deviceName }}</div>
              <div class="status">
                <div :class="e.displayColor == 1 ? 'green_w' : e.displayColor == 2 ? 'red_w' : ''"
                  v-for="e in item.monitorValues" :key="e">{{ e.deviceControlName }}{{ e.monitorValueResult }}</div>
              </div>
            </div>
          </div>
          <div class="empty2" v-if="empty2">
            <img src="https://static.nagaiot.cn/web/bigScreen/icon_nodata_white.png" alt="" />
            <div>暂无数据</div>
          </div>
        </div>
      </div>
      <div class="WaterQualityStatistics" v-if="WaterQualityStatisticsFlag">
        <div id="WaterQuality">

        </div>
      </div>
    </div>
    <RealTimeData @GetRealTimeControlData="GetRealTimeControlData" :orderId="orderId" />
    <SafetyVerificationModal />
    <FooterComponent />
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, inject, onBeforeUnmount, onMounted } from "vue";
import RealTimeData from "@/components/RealTimeData.vue";
import apiServer from "@/api/request";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import SafetyVerificationModal from "@/components/SafetyVerificationModal.vue";
import * as echarts from "echarts";
import FooterComponent from "@/components/FooterComponent.vue";

export default defineComponent({
  name: "DeviceDetails",
  components: {
    RealTimeData,
    SafetyVerificationModal,
    FooterComponent
  },
  setup() {
    const emitt: any = inject("emitt");

    const warnFlag = ref(false); //是否显示报警
    const content = ref(""); //报警内容
    const createTime = ref(""); //报警时间
    const loadingFlag1 = ref(false);
    const loadingFlag2 = ref(false);
    const orderId = ref(""); //id
    const categoryId = ref(""); //分类Id
    const empty1 = ref(false);
    const empty2 = ref(false);
    const deviceList = ref([] as any[]); //实时数据列表
    const controlByIdList = ref([] as any[]); //控制列表-id查询
    const isReadonly = ref(false);
    const router = useRouter();
    const modeList = ref([] as any[]);//运行模式数组
    const checkMethod = ref(0);
    const WaterQualityStatisticsFlag = ref(true);//是否有水质数据
    init();
    emitt.$sub("categoryIdChange", () => {
      init();
    });

    function init() {
      empty1.value = true;
      empty2.value = true;
      deviceList.value = [];
      controlByIdList.value = [];
      if (localStorage.categoryModels) {
        categoryId.value = JSON.parse(localStorage.categoryModels).categoryId;
        isReadonly.value = JSON.parse(localStorage.categoryModels).isReadonly;
      }
      if (localStorage.GetCategory) {
        orderId.value = JSON.parse(localStorage.GetCategory).orderId;
      }
      GetRealTimeControlData();
      GetMonitorDeviceListByDeviceTypeId();
    }

    //获取实时数据
    function GetRealTimeControlData() {
      let params = {
        orderId: orderId.value,
        deviceTypeId: categoryId.value,
      };
      loadingFlag1.value = true;
      apiServer.GetRealTimeControlData(params).then((res: any) => {
        loadingFlag1.value = false;
        if (res.status == 1) {
          if (res.data.list.length == 0) {
            empty1.value = true;
          } else {
            empty1.value = false;
            deviceList.value = res.data.list;
          }
        } else {
          empty1.value = true;
        }
      });
    }

    //设备id获取设备
    function GetMonitorDeviceListByDeviceTypeId() {
      const params = {
        deviceTypeId: categoryId.value,
        orderId: orderId.value,
      };
      loadingFlag2.value = true;
      apiServer.GetMonitorDeviceList(params).then((res: any) => {
        loadingFlag2.value = false;
        if (res.status == 1) {
          if (res.data.list.length != 0) {
            empty2.value = false;
            controlByIdList.value = res.data.list;
          } else {
            empty2.value = true;
          }
        } else {
          empty2.value = true;
        }
      });
    }

    function openRealTimeData() {
      emitt.$pub("openRealTimeData", deviceList.value);
    }

    //跳转设备详情
    function gotoControlDetails(item: any) {
      let device = {
        deviceTypeId: categoryId.value,
        deviceId: item.deviceId,
        deviceName: item.deviceName,
      };
      localStorage.device = JSON.stringify(device);
      router.push({
        name: "ControlDetails",
      });
    }

    GetRunModel();
    //查询运行模式
    function GetRunModel() {
      const params = {
        orderId: orderId.value,
        deviceTypeId: categoryId.value,
      }

      apiServer.GetRunModel(params).then((res: any) => {
        if (res.status == 1) {
          checkMethod.value = res.data.checkMethod;
          modeList.value = res.data.list
        }
      })
    }



    //修改运行模式
    function changeModel(item: any) {
      if (item.isChecked) {
        return
      }
      let msg = '';
      if (item.runModelValue == 1) {
        msg = `注意：切换为自动控制模式后，系统将执行自动控制逻辑，如需手动控制元器件，需要您将控制模式切换回手动控制。`
      } else {
        msg = `注意：切换为手动控制模式后，系统将不再自动执行自动控制逻辑，如需重新启用自动控制逻辑，需要您将控制模式切换回自动控制。`
      }
      ElMessageBox.confirm(
        msg,
        '提示',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        }

      )
        .then(() => {
          const params = {
            orderId: orderId.value,
            deviceTypeId: categoryId.value,
            runModelValue: item.runModelValue,
            checkMethod: checkMethod.value
          }
          const func = {
            method: SetRunModel,
            item: params
          }
          if (checkMethod.value == 2 || checkMethod.value == 3) {
            emitt.$pub("openSafetyVerificationModal", func);
          } else {
            SetRunModel(params, '')
          }

        })
        .catch(() => {
          console.log('error')
        })
    }

    //切换模式
    function SetRunModel(item: any, code: any) {
      const params = {
        orderId: item.orderId,
        deviceTypeId: item.deviceTypeId,
        runModelValue: item.runModelValue,
        code
      }
      apiServer.SetRunModel(params, params).then((res: any) => {
        if (res.status == 1) {
          ElMessage.success(res.message);
          GetRunModel();
        } else {
          ElMessage.error(res.message);
        }
      })
    }
    function GetWaterQualityStatistics() {
      const params = {
        orderId: orderId.value,
        deviceTypeId: categoryId.value
      }
      apiServer.GetWaterQualityStatistics(params).then((res: any) => {
        if (res.status == 1) {
          if (res.data.list.length > 0) {
            let xAxis = [];
            let series = [];
            let legend = [];
            for (const item of res.data.list) {
              let yAxis = [];
              xAxis = []
              for (const e of item.points) {
                xAxis.push(e.time)
                yAxis.push(e.waterQuality)
              }
              series.push({
                name:item.title,
                data: yAxis,
                type: "line",
                symbol: "circle",
                itemStyle: {
                  // color: "#0385F4",
                },
              })
              legend.push(item.title)
            }
            makeEchar(xAxis, series,legend)
          }else{
            WaterQualityStatisticsFlag.value = false;
          }
        }
      })
    }

    //水质电导率-折线图
    function makeEchar(time: any, series: any,legend:any) {
      const chartDom = document.getElementById("WaterQuality");
      const myChart = echarts.init(chartDom);
      const option = {
        title: {
          text: '水质（电导率）',
          textStyle: {
            fontSize: 16
          },
        },
        grid: {
          bottom: "0%",
          left: '10',
          containLabel: true,
        },
        legend: {
          show: true, // 是否显示图例
          data: legend,
          right:0
        },
        xAxis: {
          type: "category",
          data: time,
          axisLine: {
            lineStyle: {
              color: "#5C7AAB",
            },
          },
          axisLabel: {
            color: "#89A0BE",
          },
        },
        yAxis: {
          type: "value",
        },
        series: series,
      };
      myChart.setOption(option);
    }

    onMounted(() => {
      GetWaterQualityStatistics();
    })
    onBeforeUnmount(() => {
      emitt.$unsub("categoryIdChange");
    });
    return {
      warnFlag,
      content,
      createTime,
      loadingFlag1,
      loadingFlag2,
      empty1,
      empty2,
      deviceList,
      controlByIdList,
      openRealTimeData,
      GetRealTimeControlData,
      isReadonly,
      orderId,
      gotoControlDetails,
      modeList,
      changeModel,
      WaterQualityStatisticsFlag
    };
  },
});
</script>

<style lang="less" scoped>
.DeviceDetails-nopx {
  width: calc(100vw - 240px);
  float: right;
  min-width: 1200px;
  height: calc(100vh - 60px);
  overflow-y: scroll;
  padding-bottom: 40px;

  .module_page {
    margin: 16px 24px;
    display: flex;
    justify-content: space-between;

    .warn {
      height: 48px;
      background: #f4dada;
      display: flex;
      justify-content: left;
      align-items: center;
      padding-left: 36px;
      font-size: 14px;
      color: #ff0000;

      img {
        width: 18px;
        height: 18px;
      }

      span {
        &:first-of-type {
          margin-left: 12px;
        }

        &:last-child {
          margin-left: 40px;
        }
      }
    }

    .control_panel {
      margin-right: 24px;

      .control_mode {
        height: 64px;
        background: white;
        margin-bottom: 16px;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;

        .control_mode_title {
          display: flex;
          justify-content: left;
          align-items: center;
          font-weight: 500;
        }

        .mode {
          display: flex;

          .mode_item {
            &:first-child {
              margin-right: 40px;
            }
          }
        }

        img {
          width: 16px;
          height: 16px;
          margin-left: 8px;
        }
      }

      .control_panel_left {
        background: white;
        border-radius: 8px;



        .panel_box {
          .panel_head {
            padding: 0 16px;
            height: 52px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #e7e7e7;
          }

          .panel_body {
            padding: 20px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .panel_item {
              width: 170px;
              height: 80px;
              padding-top: 13px;
              border-radius: 4px;

              .panel_item_bottom {
                margin-top: 7px;
                color: #424248;
              }

              .open {
                color: #099e69;
              }

              .close {
                color: #ff0000;
              }
            }

            .empty1 {
              width: 112px;
              display: flex;
              justify-content: space-between;
              font-size: 12px;
              align-items: center;
              margin: 0 auto;

              img {
                width: 60px;
              }
            }
          }
        }
      }

      .controlByIdList {
        margin-top: 16px;
        background: white;
        padding: 24px 16px;
        border-radius: 8px;
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        padding-right: 0;
        padding-bottom: 0;

        .control_item {
          width: 224px;
          background: #f5f7f9;
          border-radius: 4px;
          margin-right: 24px;
          padding: 12px;
          margin-bottom: 24px;
          display: flex;
          justify-content: left;
          align-items: center;

          img {
            width: 70px;
            height: 96px;
          }

          .item_right {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 16px;
          }

          .name {
            color: #17233d;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 8px;
          }

          .status {
            font-size: 14px;
            text-align: left;
          }
        }

        .empty2 {
          margin: 200px auto;

          img {
            width: 150px;
          }

          div {
            font-size: 12px;
            margin-top: 20px;
          }
        }
      }
    }

    .WaterQualityStatistics {
      width: 608px;
      background: white;
      border-radius: 8px;
      padding: 20px 10px;

      #WaterQuality {
        height: 400px;
      }
    }
  }
}
</style>
<style lang="less">
.DeviceDetails-nopx {
  .el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
  }
}
</style>