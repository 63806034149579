<template>
  <div class="AutomaticTriggerModal-nopx">
    <el-dialog v-model="dialogVisible" title="自动触发设置" width="752" :show-close="false">
      <div class="box">
        <el-tabs v-model="activeName" class="demo-tabs">
          <el-tab-pane label="定时生效" name="1">
            <div class="timing">
              <div class="timing_item" v-for="item in timingTriggerItems" :key="item.id">
                <div class="timing_item_v1">
                  <img class="cs" src="https://static.nagaiot.cn/web/icon_del.png" alt=""
                    @click="delTiming(item)" />
                </div>
                <div class="timing_item_v2" v-if="device.dataType == 'Bit'">
                  <div class="lable">目标状态</div>
                  <el-select v-model="item.targetResultValue" placeholder="请选择">
                    <el-option value="1" :label="device.openStatusDesc"></el-option>
                    <el-option value="0" :label="device.closeStatusDesc"></el-option>
                  </el-select>
                </div>
                <div class="timing_item_v2" v-else>
                  <div class="lable">目标值</div>
                  <el-input type="number" v-model="item.targetResultValue" placeholder="请输入"></el-input>
                </div>
                <div class="timing_item_v3">
                  <div class="lable">频率</div>
                  <el-select v-model="item.cyclePeriodValue" placeholder="请选择">
                    <el-option value="2" label="每天"></el-option>
                    <el-option value="1" label="仅一次"></el-option>
                  </el-select>
                </div>
                <div class="timing_item_v4">
                  <div class="lable">生效时间</div>
                  <div class="demo-range">
                    <el-time-picker v-model="item.startTime" format="HH:mm" />
                    <div class="to">至</div>
                    <el-time-picker v-model="item.endTime" format="HH:mm" />
                  </div>
                </div>
                <div class="timing_item_v5">
                  <el-switch v-model="item.status" />
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="触发生效" name="2">
            <div class="condition">
              <div class="timing_item" v-for="item in conditionTriggerItems" :key="item.id">
                <div class="timing_item_v1">
                  <img class="cs" src="https://static.nagaiot.cn/web/icon_del.png" alt=""
                    @click="delCondition(item)" />
                </div>
                <div class="timing_item_v2" v-if="device.dataType == 'Bit'">
                  <div class="lable">目标状态</div>
                  <el-select v-model="item.targetResultValue" placeholder="请选择">
                    <el-option value="1" :label="device.openStatusDesc"></el-option>
                    <el-option value="0" :label="device.closeStatusDesc"></el-option>
                  </el-select>
                </div>
                <div class="timing_item_v2" v-else>
                  <div class="lable">目标值</div>
                  <el-input type="number" v-model="item.targetResultValue" placeholder="请输入"></el-input>
                </div>
                <div class="timing_item_v3">
                  <div class="lable">频率</div>
                  <el-select v-model="item.cyclePeriodValue" placeholder="请选择">
                    <el-option value="2" label="每天"></el-option>
                    <el-option value="1" label="仅一次"></el-option>
                  </el-select>
                </div>
                <div class="timing_item_v4">
                  <div class="lable">触发条件
                  </div>
                  <div class="demo-range">
                    <el-select class="device" v-model="item.triggerConditionDeviceControlId" placeholder="请选择"
                      @change="triggerConditionDeviceIdChange(item)">
                      <el-option v-for="e in controlList" :key="e.deviceControlId" :value="e.deviceControlId"
                        :label="e.deviceControlName"></el-option>
                    </el-select>
                    <el-select class="operator" v-model="item.operator" placeholder="请选择">
                      <el-option value="1" label="="></el-option>
                      <el-option v-show="!item.disabled" value="2" label=">"></el-option>
                      <el-option v-show="!item.disabled" value="3" label="≥"></el-option>
                      <el-option v-show="!item.disabled" value="4" label="<"></el-option>
                      <el-option v-show="!item.disabled" value="5" label="≤"></el-option>
                    </el-select>
                    <el-input class="condition_result" v-model="item.triggerConditionResult" placeholder="请输入"
                      v-show="!item.disabled"></el-input>
                    <el-select v-model="item.triggerConditionResult" placeholder="请选择" v-show="item.disabled">
                      <el-option value="1" :label="device.openStatusDesc"></el-option>
                      <el-option value="0" :label="device.closeStatusDesc"></el-option>
                    </el-select>
                  </div>
                </div>
                <div class="timing_item_v5">
                  <el-switch v-model="item.status" />
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <div>
            <el-button class="btn" type="success" @click="add">添加</el-button>
          </div>
          <div>
            <el-button class="btn" @click="dialogVisible = false">取消</el-button>
            <el-button class="btn" type="primary" @click="save">
              保存
            </el-button>
          </div>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  reactive,
  inject,
  onBeforeUnmount,
  PropType,
} from "vue";
import { ElMessage } from "element-plus";
import apiServer from "@/api/request";
import { Control } from "@/utils/types";
export default defineComponent({
  props: {
    orderId: {
      type: String,
    },
    controlList: {
      type: Array as PropType<Control[]>,
      default: [] as PropType<Control[]>,
    },
  },
  name: "AutomaticTriggerModal",
  setup(props, context) {
    const emitt: any = inject("emitt");
    const dialogVisible = ref(false);
    const device = reactive({
      dataType: "",
      openStatusDesc: "",
      closeStatusDesc: "",
      checkMethod: ''
    });
    const activeName = ref("1");
    const timingTriggerItems = ref([] as any[]); //定时生效列表
    const conditionTriggerItems = ref([] as any[]); //触发生效列表
    const deviceControlId = ref(""); //设备id
    //注册-打开弹窗
    emitt.$sub("openAutomaticTriggerModal", (val: any) => {
      let v = val[0];
      console.log("v", v);
      Object.assign(device, v);
      //定时生效数据
      let arr = [];
      for (let item of v.timingTriggerItems) {
        let obj = {
          id: 0, //触发Id,
          timeType: 1, //1-时间点,2-时间段
          startTime: "",
          endTime: "", //非必传
          cyclePeriodValue: "", //周期
          targetResultValue: "", //触发结果
          status: false, //1-启用，2-禁用
        };
        obj.id = item.id;
        obj.startTime = `Tue Jul 18 2023 ${item.startTime}:00 GMT+0800 (中国标准时间)`;
        if (item.timeType == 1) {
          obj.endTime = "";
        } else {
          obj.endTime = `Tue Jul 18 2023 ${item.endTime}:00 GMT+0800 (中国标准时间)`;
        }
        obj.cyclePeriodValue = item.cyclePeriodValue.toString();
        obj.targetResultValue = item.targetResultValue;
        obj.status = item.status == 1;
        arr.push(obj);
      }
      timingTriggerItems.value = arr;

      //触发生效数据
      let arr2 = [];
      for (let item of v.conditionTriggerItems) {
        console.log("conditionTriggerItems", v.conditionTriggerItems);
        let obj = {
          id: 0,
          status: false,
          cyclePeriodValue: 0, //循环周期
          triggerConditionDeviceControlId: 0, //触发表达式设备Id
          triggerConditionResult: 0, //触发表达式设备达到的值
          targetResultValue: "0", //触发结果
          operator: 1, //运算符
        };
        obj.id = item.id;
        obj.cyclePeriodValue = item.cyclePeriodValue.toString();
        obj.triggerConditionDeviceControlId = item.triggerConditionDeviceControlId;
        obj.triggerConditionResult = item.triggerConditionResult.toString();
        obj.targetResultValue = item.targetResultValue;
        obj.operator = item.operator.toString();
        obj.status = item.status == 1;
        giveDisabled(obj);
        arr2.push(obj);
      }
      conditionTriggerItems.value = arr2;
      console.log("arr2", arr2);
      deviceControlId.value = v.deviceControlId;
      dialogVisible.value = true;
    });
    onBeforeUnmount(() => {
      emitt.$unsub("openAutomaticTriggerModal");
    });

    function giveDisabled(element: any) {
      let arr = props.controlList.filter((e: Control) => {
        return e.deviceControlId == element.triggerConditionDeviceControlId;
      });
      let item = arr[0];
      console.log(item);
      if (item.dataType == "Bit") {
        element.disabled = true;
      } else {
        element.disabled = false;
      }
    }

    //保存
    function save() {
      if (device.checkMethod.toString() == "2" || device.checkMethod.toString() == "3") {
        const func = {
          method: set,
          item: device
        }
        emitt.$pub("openSafetyVerificationModal", func);
      }else{
        set();
      }
    }

    function set() {
      if (activeName.value == "1") {
        SetTimerTrigger();
      } else {
        SetConditionTrigger();
      }
    }
    //保存条件触发
    function SetConditionTrigger() {
      let conditionItems = [];
      for (let item of conditionTriggerItems.value) {
        console.log("SetConditionTrigger", item);
        if (item.targetResultValue == null) {
          ElMessage.error("请选择目标状态");
          return;
        }
        if (item.cyclePeriodValue == "") {
          ElMessage.error("请选择频率");
          return;
        }
        if (item.triggerConditionDeviceId == null || item.triggerConditionDeviceId == "") {
          ElMessage.error("请选择触发条件");
          return;
        }
        if (item.operator == "") {
          ElMessage.error("请选择运算符");
          return;
        }
        if (item.triggerConditionResult == null || item.triggerConditionResult == "") {
          ElMessage.error("请选择或输入设备值");
          return;
        }
        let obj = {
          id: 0,
          status: 0,
          cyclePeriod: 0, //循环周期
          triggerConditionDeviceId: 0, //触发表达式设备Id
          triggerConditionResult: 0, //触发表达式设备达到的值
          targetResultValue: "0", //触发结果
          operator: 1, //运算符
        };
        obj.id = item.id;
        obj.status = item.status ? 1 : 2;
        obj.cyclePeriod = item.cyclePeriodValue;
        obj.triggerConditionDeviceId = item.triggerConditionDeviceId;
        obj.triggerConditionResult = item.triggerConditionResult;
        obj.targetResultValue = item.targetResultValue;
        obj.operator = item.operator;
        conditionItems.push(obj);
      }
      let data = {
        orderId: props.orderId,
        monitorDeviceId: deviceControlId.value,
      };
      let params = {
        body: conditionItems,
      };
      apiServer.SetConditionTrigger(params, data).then((res: any) => {
        if (res.status == 1) {
          dialogVisible.value = false;
          ElMessage.success("保存成功");
          context.emit("GetControlList");
        }
      });
    }

    //保存定时触发
    function SetTimerTrigger() {
      let timingItems = [];
      for (let item of timingTriggerItems.value) {
        let obj = {
          id: 0, //触发Id,
          timeType: 1, //1-时间点,2-时间段
          startTime: "",
          endTime: "", //非必传
          cyclePeriod: 1,
          targetResultValue: "", //触发结果
          status: 1, //1-启用，2-禁用
        };
        obj.id = item.id;
        if (item.cyclePeriodValue == "") {
          ElMessage.error("请选择频率");
          return;
        }
        if (item.targetResultValue == "") {
          ElMessage.error("请选择目标状态");
          return;
        }
        if (item.startTime == "") {
          ElMessage.error("请选择开始时间");
          return;
        } else {
          obj.startTime = item.startTime.toString().substring(16, 21);
        }
        obj.endTime = item.endTime
          ? item.endTime.toString().substring(16, 21)
          : "";
        obj.cyclePeriod = item.cyclePeriodValue;
        obj.targetResultValue = item.targetResultValue;
        obj.status = item.status ? 1 : 2;
        obj.timeType = item.endTime == "" ? 1 : 2;
        timingItems.push(obj);
      }
      let data = {
        orderId: props.orderId,
        deviceControlId: deviceControlId.value,
      };
      let params = {
        body: timingItems,
      };
      apiServer.SetTimerTrigger(params, data).then((res: any) => {
        if (res.status == 1) {
          dialogVisible.value = false;
          ElMessage.success("保存成功");
          context.emit("GetControlList");
        } else {
          ElMessage.error(res.message);
        }
      });
    }
    //添加
    function add() {
      if (activeName.value == "1") {
        let obj = {
          id: 0, //触发Id,
          timeType: 1, //1-时间点,2-时间段
          startTime: "",
          endTime: "", //非必传
          cyclePeriodValue: "", //周期
          targetResultValue: "", //触发结果
          status: true, //1-启用，2-禁用
        };
        timingTriggerItems.value.push(obj);
      } else {
        let obj = {
          id: 0,
          status: true,
          cyclePeriodValue: "", //循环周期
          triggerConditionDeviceId: null, //触发表达式设备Id
          triggerConditionResult: null, //触发表达式设备达到的值
          targetResultValue: null, //触发结果
          operator: "", //运算符
        };
        conditionTriggerItems.value.push(obj);
      }
    }

    //删除-定时
    function delTiming(e: any) {
      timingTriggerItems.value = timingTriggerItems.value.filter(
        (item) => e.id != item.id
      );
    }

    //删除-触发
    function delCondition(e: any) {
      conditionTriggerItems.value = conditionTriggerItems.value.filter(
        (item) => e.id != item.id
      );
    }

    //选择触发条件
    function triggerConditionDeviceIdChange(element: any) {
      let arr = props.controlList.filter((e: Control) => {
        return e.deviceControlId == element.triggerConditionDeviceId;
      });
      let item = arr[0];
      if (item.dataType == "Bit") {
        element.disabled = true;
        element.operator = '1';
        element.triggerConditionResult = '1';
      } else {
        element.disabled = false;
        element.triggerConditionResult = '';
      }
    }
    return {
      dialogVisible,
      activeName,
      timingTriggerItems,
      conditionTriggerItems,
      device,
      save,
      add,
      delTiming,
      delCondition,
      triggerConditionDeviceIdChange,
    };
  },
});
</script>

<style scoped lang="less">
.AutomaticTriggerModal-nopx {
  .box {

    .timing,
    .condition {
      padding: 0 25px;
    }

    .timing_item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      background: #f6f7f8;
      align-items: flex-end;
      border-radius: 4px;
      padding: 16px 15px;
      margin-bottom: 16px;

      .lable {
        text-align: left;
        margin-bottom: 10px;
      }
    }

    .timing_item_v1 {
      width: 20px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .timing_item_v2 {
      width: 120px;
    }

    .timing_item_v3 {
      width: 120px;
    }

    .timing_item_v4 {
      width: 300px;

      .demo-range {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .device,
        .condition_result {
          width: 320px;
        }

        .operator {
          margin: 0 10px;
        }
      }

      .to {
        margin: 0 10px;
      }
    }

    .timing_item_v5 {
      width: 44px;
    }
  }

  .dialog-footer {
    display: flex;
    justify-content: space-between;

    .btn {
      width: 160px;
    }
  }
}
</style>
<style lang="less">
.AutomaticTriggerModal-nopx {
  .el-tabs {
    --el-tabs-header-height: 42px;
  }

  .el-dialog__body {
    padding-top: 5px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .el-tabs__nav {
    width: 100%;
  }

  .box {
    .el-tabs__item {
      width: 50% !important;
      padding: 0 !important;
    }
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__wrapper {
    width: 100%;
  }
}
</style>
