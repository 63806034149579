import { Params } from "@/customizeObj/params";
import config from "./config";
import axios from 'axios';
import { Storage } from "@/customizeObj/storage";
import router from '@/router/index'
import Security from '@/utils/encode'
import {urlEncode} from '@/utils/common'

let serverUrlObj = config.testBaseUrl;
const storage = new Storage();
//判断启动命令参数区分接口线上域名还是测试域名
if (process.env.VUE_APP_ENV == 'dev') {
  serverUrlObj = config.testBaseUrl;
}else if(process.env.VUE_APP_ENV == 'local'){
  serverUrlObj = config.loaclBaseUrl;
}else{
  serverUrlObj = config.masterBaseUrl;
}


//请求对象
class Server {
  baseUrl: string;
  instance: any
  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.instance = axios.create({
      baseURL: this.baseUrl,
      timeout: config.timeout,
      headers: {
        'content-Type': 'application/json',
      },
    });



    //响应拦截
    this.instance.interceptors.response.use((response: any) => {
      let responseData = response.data;
      if (response.data.status == 100) {
        router.push("/loginPage");
      }
      if (typeof responseData !== 'object') {
        const resStr = Security.decode(responseData);
        responseData = JSON.parse(resStr);
      }
      return responseData;
    }, (error: any) => {
      console.log(error, typeof error)
    })

    //请求拦截
    this.instance.interceptors.request.use((confi: any) => {
      const baseConfig = confi;
      let isEncode = false;
      // delete baseConfig.data?.encode;
      if (baseConfig.method === 'post') { // post请求
        isEncode = Boolean(baseConfig.data.encode);
        delete baseConfig.data.encode;
        if (isEncode) { // 需要加密的数据
          const param = Security.encode(urlEncode(baseConfig.data));
          baseConfig.data = { param };
        }
        if (baseConfig.data?.method == 'json') {
          Object.assign(baseConfig.headers, {
            'content-Type': 'application/json',
          });
          delete baseConfig.data.method;
          if(Object.prototype.hasOwnProperty.call(baseConfig.data, 'body')){
            const body = baseConfig.data.body
            baseConfig.data = body;
          }
        } else {
          delete baseConfig.data?.method;
          Object.assign(baseConfig.headers, {
            'content-Type': 'application/x-www-form-urlencoded',
          });
        }
      }
      return baseConfig;
    }, (err: any) => {
      console.error('网络异常：', err);
    });
  }

  get = (url: string, params = {}) => {
    const userToken = storage.localGet('userToken') ? storage.localGet('userToken') : null;
    const param = new Params(userToken);
    const cpParams = param.getAssignParams(params)
    return this.instance.get(url, { params: cpParams });
  }


  post = (url: string, params = {}, data = {}, method = '', encode = false) => {
    const userToken = storage.localGet('userToken') ? storage.localGet('userToken') : null;
    const param = new Params(userToken);
    console.log("post",data)
    const cpParams = Object.assign(param, data).getUrlEncode()
    const datas = Object.assign(params, { encode, method });
    return this.instance.post(`${url}?${cpParams}`, datas);
  }
}

//用户站点
const http = new Server(serverUrlObj.http);
//控制相关站点
const controlhttp = new Server(serverUrlObj.controlhttp);

const server = {
  http,
  controlhttp
}

export default server